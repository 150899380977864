export const ErrorGrafik: React.FunctionComponent<
  React.SVGAttributes<SVGElement>
> = () => {
  return (
    <svg
      width="195"
      height="105"
      viewBox="0 0 195 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="var(--layer-1)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 48.236 114 C 22.085 114 9.707 106.8 4.339 94.263 C -1.029 81.727 -5.622 57.569 17.761 41.783 C 41.143 25.998 51.332 30.945 65.392 31.195 C 85.482 31.552 92.202 12.338 112.159 10.009 C 130.25 7.898 186.449 14.579 194.069 50.166 C 196.974 63.736 193.238 79.906 180.648 91.43 C 169.485 101.646 163.7 104.953 148.686 106.8 C 132.593 108.779 112.135 100.122 95.97 101.399 C 77.125 102.889 74.386 114 48.236 114 Z"
      />
      <path
        fill="var(--layer-2)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 49.537 88.81 C 48.606 88.81 47.851 88.055 47.851 87.123 L 47.851 74.368 L 28.109 74.368 C 26.132 74.368 24.892 72.371 25.874 70.767 L 35.443 55.112 L 31.543 55.112 C 29.555 55.112 28.317 53.096 29.318 51.491 L 40.074 34.251 L 36.693 34.251 L 36.512 34.243 L 36.499 34.243 C 36.494 34.243 36.488 34.243 36.483 34.243 C 36.451 34.241 36.42 34.238 36.389 34.235 C 36.336 34.229 36.283 34.222 36.232 34.213 C 36.144 34.198 36.058 34.18 35.975 34.158 C 35.973 34.156 35.97 34.155 35.967 34.154 L 35.963 34.153 C 35.429 34.01 34.981 33.71 34.66 33.323 L 34.626 33.28 C 34.595 33.242 34.566 33.203 34.54 33.163 C 34.526 33.147 34.514 33.128 34.502 33.11 L 34.446 33.019 C 34.408 32.954 34.372 32.89 34.341 32.824 L 34.312 32.76 L 34.277 32.68 C 34.016 32.03 34.051 31.268 34.495 30.589 L 53.378 1.705 C 54.382 0.169 56.769 0.169 57.773 1.705 L 76.656 30.589 C 77.703 32.194 76.469 34.251 74.459 34.251 L 71.073 34.251 L 81.833 51.491 C 82.834 53.096 81.596 55.112 79.608 55.112 L 75.708 55.112 L 85.277 70.767 C 86.259 72.371 85.019 74.368 83.042 74.368 L 63.3 74.368 L 63.297 87.124 C 63.297 88.055 62.542 88.81 61.611 88.81 L 49.537 88.81 Z M 41.284 29.437 L 55.576 7.577 L 69.866 29.437 L 66.591 29.437 C 64.603 29.437 63.366 31.452 64.366 33.057 L 75.124 50.298 L 55.576 50.298 C 54.154 50.298 53.001 51.376 53.001 52.705 C 53.001 54.033 54.154 55.112 55.576 55.112 L 69.775 55.112 L 78.604 69.554 L 60.725 69.554 C 59.304 69.554 58.151 70.632 58.151 71.961 L 58.151 88.81 L 53.001 88.81 L 53.001 71.961 C 53.001 70.632 51.847 69.554 50.426 69.554 L 32.547 69.554 L 42.117 53.898 C 43.097 52.294 41.858 50.298 39.88 50.298 L 36.026 50.298 L 46.038 34.251 L 54.161 34.251 C 55.584 34.251 56.736 33.173 56.736 31.844 C 56.736 30.515 55.584 29.437 54.161 29.437 L 41.284 29.437 Z"
      />
      <path
        fill="var(--layer-2)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 123.365 43.545 C 124.059 49.106 117.128 53.508 117.128 53.508 L 144.738 53.508 C 161.234 53.508 166.903 46.263 169.72 41.084 C 172.538 35.904 179.343 15.029 156.766 5.455 C 134.19 -4.12 119.32 16.755 119.32 26.172 C 119.32 35.59 122.671 37.985 123.365 43.545 Z M 145.022 38.3 C 146.192 38.3 147.142 39.3 147.142 40.533 C 147.142 41.766 146.192 42.766 145.022 42.766 C 143.85 42.766 142.901 41.766 142.901 40.533 C 142.901 39.3 143.85 38.3 145.022 38.3 Z M 153.502 21.565 C 153.502 24.717 152.192 26.316 149.312 28.179 C 149.164 28.274 149.164 28.274 149.022 28.366 C 147.465 29.371 147.142 29.747 147.142 30.49 L 147.142 33.837 C 147.142 35.069 146.192 36.068 145.022 36.068 C 143.85 36.068 142.901 35.069 142.901 33.837 L 142.901 30.49 C 142.901 27.694 144.088 26.317 146.804 24.562 C 146.95 24.468 146.95 24.468 147.092 24.377 C 148.836 23.249 149.262 22.729 149.262 21.565 C 149.262 17.335 141.836 16.655 140.733 22.035 C 140.486 23.24 139.358 24.006 138.214 23.746 C 137.069 23.486 136.342 22.299 136.588 21.094 C 138.749 10.558 153.502 11.909 153.502 21.565 Z"
      />
    </svg>
  );
};
