import styles from '../error.module.css';
import { Button, cn } from '@lhb/ui';
import { Link } from '@remix-run/react';

type Error401Props = {
  link?: React.ElementType;
};

/**
 * @TODO: Convert to i18n compatible
 */
export function Error401({ link: AsLink = Link }: Error401Props) {
  return (
    <>
      <h1 className={cn('display', styles['heading'])}>
        Sessionen har gått ut
      </h1>
      <p className={cn('intro')}>
        Vänligen logga ut och in igen för att fortsätta.
      </p>
      <Button asChild trailingIcon="logout">
        <AsLink to="/logout">Logga ut</AsLink>
      </Button>
    </>
  );
}
