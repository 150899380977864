import { SvgSymbol } from '../symbol-factory';

export const Download: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 17 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 16.8 17.706 L 16.8 16.15 C 16.8 15.577 16.336 15.113 15.763 15.113 C 15.19 15.113 14.725 15.577 14.725 16.15 L 14.725 17.706 C 14.725 17.993 14.493 18.225 14.206 18.225 L 2.794 18.225 C 2.507 18.225 2.275 17.993 2.275 17.706 L 2.275 16.15 C 2.275 15.577 1.81 15.113 1.237 15.113 C 0.664 15.113 0.2 15.577 0.2 16.15 L 0.2 17.706 C 0.2 19.139 1.361 20.3 2.794 20.3 L 14.206 20.3 C 14.894 20.3 15.554 20.026 16.04 19.54 C 16.526 19.054 16.8 18.394 16.8 17.706 Z" />
    <path d="M 12.347 12.734 C 12.752 12.329 12.752 11.671 12.347 11.266 C 11.941 10.861 11.284 10.861 10.879 11.266 L 9.538 12.607 L 9.538 4.737 C 9.538 4.164 9.073 3.7 8.5 3.7 C 7.927 3.7 7.463 4.164 7.463 4.737 L 7.463 12.607 L 6.122 11.266 C 5.716 10.861 5.059 10.861 4.654 11.266 C 4.248 11.671 4.248 12.329 4.654 12.734 L 7.767 15.846 C 7.961 16.042 8.225 16.151 8.5 16.151 C 8.776 16.151 9.039 16.042 9.234 15.846 L 12.347 12.734 Z" />
  </svg>
);
