import { HTMLAttributes } from 'react';

export function ErrorGrafik(props: HTMLAttributes<SVGElement>) {
  return (
    <svg viewBox="0 0 337 313" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="var(--layer-1)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 227.449 115.426 C 268.93 106.761 295.928 114.655 311.451 127.926 C 326.973 141.196 352.811 181.151 320.947 213.926 C 289.083 246.701 251.95 247.786 219.964 253.876 C 187.979 259.966 171.95 290.243 120.45 307.426 C 92.451 316.767 33.437 320.331 9.565 266.434 C 0.462 245.882 1.034 219.006 17.189 196.565 C 31.513 176.669 39.595 169.508 62.799 161.606 C 87.673 153.136 122.992 160.083 148.21 152.702 C 177.611 144.097 185.967 124.09 227.449 115.426 Z"
      />
      <path
        fill="var(--layer-2)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 142.645 108.64 C 144.486 123.393 126.099 135.072 126.099 135.072 L 199.348 135.072 C 243.113 135.072 258.151 115.85 265.626 102.109 C 273.101 88.367 291.154 32.986 231.258 7.585 C 171.363 -17.817 131.913 37.565 131.913 62.549 C 131.913 87.534 140.804 93.887 142.645 108.64 Z M 200.1 94.725 C 203.206 94.725 205.725 97.377 205.725 100.649 C 205.725 103.92 203.206 106.572 200.1 106.572 C 196.993 106.572 194.475 103.92 194.475 100.649 C 194.475 97.377 196.993 94.725 200.1 94.725 Z M 222.599 50.325 C 222.599 58.688 219.124 62.931 211.482 67.873 C 211.091 68.125 211.091 68.125 210.713 68.369 C 206.583 71.036 205.725 72.033 205.725 74.003 L 205.725 82.883 C 205.725 86.152 203.206 88.802 200.1 88.802 C 196.993 88.802 194.475 86.152 194.475 82.883 L 194.475 74.003 C 194.475 66.587 197.622 62.932 204.829 58.278 C 205.216 58.028 205.216 58.028 205.592 57.785 C 210.22 54.793 211.349 53.414 211.349 50.325 C 211.349 39.103 191.65 37.3 188.723 51.574 C 188.068 54.769 185.075 56.801 182.039 56.111 C 179.002 55.422 177.072 52.272 177.727 49.076 C 183.458 21.125 222.599 24.708 222.599 50.325 Z"
      />
      <path
        fill="var(--layer-2)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 52.429 223.86 C 50.22 223.86 48.429 222.069 48.429 219.86 L 48.429 193.097 L 6.377 193.097 C 2.165 193.097 -0.476 188.844 1.616 185.426 L 21.998 152.078 L 13.69 152.078 C 9.456 152.078 6.819 147.785 8.951 144.367 L 31.864 107.642 L 24.661 107.642 L 24.277 107.625 L 24.249 107.625 C 24.237 107.624 24.225 107.626 24.214 107.625 C 24.147 107.62 24.08 107.614 24.013 107.608 C 23.9 107.595 23.789 107.58 23.678 107.561 C 23.491 107.53 23.309 107.491 23.132 107.444 C 23.127 107.439 23.121 107.438 23.115 107.436 L 23.106 107.434 C 21.969 107.129 21.015 106.49 20.331 105.666 L 20.258 105.574 C 20.192 105.492 20.13 105.41 20.075 105.325 C 20.046 105.29 20.02 105.249 19.995 105.212 L 19.874 105.017 C 19.793 104.88 19.716 104.743 19.651 104.603 L 19.589 104.467 L 19.516 104.296 C 18.96 102.911 19.033 101.288 19.98 99.842 L 60.203 38.314 C 62.342 35.043 67.425 35.043 69.565 38.314 L 109.788 99.842 C 112.019 103.26 109.39 107.642 105.108 107.642 L 97.896 107.642 L 120.817 144.367 C 122.949 147.785 120.312 152.078 116.078 152.078 L 107.77 152.078 L 128.152 185.426 C 130.244 188.844 127.603 193.097 123.391 193.097 L 81.339 193.097 L 81.332 219.862 C 81.332 222.071 79.541 223.861 77.332 223.861 L 52.429 223.86 Z M 34.442 97.388 L 64.884 50.822 L 95.326 97.388 L 88.349 97.388 C 84.115 97.388 81.478 101.681 83.61 105.099 L 106.526 141.824 L 64.884 141.824 C 61.856 141.824 59.399 144.121 59.399 146.951 C 59.399 149.781 61.856 152.078 64.884 152.078 L 95.132 152.078 L 113.939 182.842 L 75.854 182.842 C 72.826 182.842 70.369 185.139 70.369 187.969 L 70.369 223.86 L 59.399 223.86 L 59.399 187.969 C 59.399 185.139 56.942 182.842 53.914 182.842 L 15.829 182.842 L 36.216 149.494 C 38.303 146.076 35.664 141.824 31.451 141.824 L 23.241 141.824 L 44.567 107.642 L 61.871 107.642 C 64.902 107.642 67.356 105.345 67.356 102.515 C 67.356 99.684 64.902 97.388 61.871 97.388 L 34.442 97.388 Z"
      />
    </svg>
  );
}
