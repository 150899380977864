import { forwardRef } from 'react';
import style from './multiline.module.css';
import { cn } from '../../../../utils';
import { FieldWrapper } from '../field-wrapper';

export type MultilineProps =
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    invert?: boolean;
    resize?: 'both' | 'horizontal' | 'vertical';
    label?: string;
  };

export const Multiline = forwardRef<HTMLTextAreaElement, MultilineProps>(
  ({ invert, resize, label, ...props }, ref) => (
    <FieldWrapper id={props.id} label={label}>
      <textarea
        className={cn(
          style.element,
          invert && style.invert,
          resize && style[`resize-${resize}`],
        )}
        ref={ref}
        {...props}
      />
    </FieldWrapper>
  ),
);
