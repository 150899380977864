import { EbbotLink } from '@lhb/ebbot';
import { FooterMenu, FooterMenuItem, FooterSection } from '@lhb/ui';
import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import appStyles from './app.module.css';

export function Footer() {
  const { t } = useTranslation('FOOTER');
  const [version, setVersion] = useState('0.0.0');

  useLayoutEffect(() => {
    // get the version from the meta tag in the head
    // the name of the meta tag is "fe-version"
    const meta = document.querySelector('meta[name="fe-version"]');
    if (meta) {
      setVersion(meta.getAttribute('content') || '0.0.0');
    }
  });

  return (
    <>
      <FooterSection title={t('TAG_LINE')}>
        <a href="https://www.landshypotek.se">www.landshypotek.se</a>
        <div className={appStyles['version']}>IDP: {version}</div>
      </FooterSection>
      <FooterSection
        icon="phone"
        title={<a href="tel:+46771440020">0771-44 00 20</a>}
      >
        {t('BUSINESS_HOURS', {
          open: '08:00',
          close: '17:00',
        })}
      </FooterSection>
      <FooterSection
        icon="chat"
        title={<EbbotLink>{t('CHAT_LINK_LABEL')}</EbbotLink>}
      />
      <FooterSection>
        <FooterMenu>
          <FooterMenuItem
            as={Link}
            target="_blank"
            to="https://www.landshypotek.se/kundservice/kund-hos-oss/personuppgiftshantering/"
            label={t('PRIVACY_POLICY_LINK_LABEL')}
          />
          <FooterMenuItem
            as={Link}
            target="_blank"
            to="https://www.landshypotek.se/kundservice/cookies/"
            label={t('COOKIES_LINK_LABEL')}
          />
        </FooterMenu>
      </FooterSection>
    </>
  );
}
