import { PropsWithChildren, forwardRef } from 'react';
import { Icon } from '../symbols';
import styles from './dialog.module.css';
import { createPortal } from 'react-dom';
import { ClassName, cn } from '../../utils';
import { useDialog } from './dialog.hook';

type DialogProps = PropsWithChildren<{
  title?: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  id: string;
  open?: boolean;
  bottom?: boolean;
  className?: ClassName;
  dismissible?: boolean;
  closeAria?: string;
}>;
const Dialog = forwardRef<HTMLDialogElement, DialogProps>(
  (
    {
      children,
      title,
      onClose,
      open,
      bottom,
      id,
      className,
      closeAria,
      dismissible,
    },
    dialogRef,
  ) => {
    const { close, innerRef } = useDialog(dialogRef, onClose, dismissible);

    return createPortal(
      <dialog
        ref={innerRef}
        id={id}
        className={cn(styles['dialog'], bottom && styles['bottom'], className)}
        open={open}
      >
        <div className={styles['header']}>
          <div className={styles['header-title']}>{title}</div>
          {dismissible && (
            <button
              onClick={close}
              className={styles['close-button']}
              type="button"
              title={closeAria}
            >
              <Icon name="cross" />
            </button>
          )}
        </div>
        <div className={styles['content']}>{children}</div>
      </dialog>,
      document.body,
    );
  },
);

export { Dialog };
