import { SvgSymbol } from '../symbol-factory';

export const House: SvgSymbol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M19.002 13.9999V21.9999H14.002V16.9999H10.002V21.9999H5.00202V13.9999H19.002ZM12.7091 3.29289L21.7091 12.2929C22.3391 12.9229 21.8929 13.9999 21.002 13.9999H3.00202C2.11111 13.9999 1.66493 12.9229 2.29489 12.2929L5 9.58586V4.99992H8V6.58586L11.2949 3.29289C11.6854 2.90237 12.3186 2.90237 12.7091 3.29289Z" />
  </svg>
);
