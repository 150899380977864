import { useSeason } from '../../styles';
import { cn } from '../../utils';
import { Branch, StrawLarge, StrawSmall, PineTree } from './assets';
import styles from './site-footer.module.css';

export function FooterDecoration() {
  const season = useSeason();
  if (season.name === 'autumn')
    return <Branch className={cn(styles['branch'])} fill="var(--layer-2)" />;

  if (season.name === 'winter')
    return (
      <>
        <PineTree
          className={cn(styles['pine-tree'], styles['left-pine-tree'])}
          fill="var(--layer-2)"
        />
        <PineTree
          className={cn(styles['pine-tree'], styles['right-pine-tree'])}
          fill="var(--layer-2)"
        />
      </>
    );

  return (
    <>
      <StrawLarge
        className={cn(styles['straw-large'], 'straw')}
        fill="var(--layer-2)"
      />
      <StrawSmall
        className={cn(styles['straw-small'], 'straw')}
        fill="var(--layer-2)"
      />
    </>
  );
}
