import { SvgSymbol } from '../symbol-factory';

export const Euro: SvgSymbol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1657 4.79004L16.4862 7.33154C15.7679 6.39232 14.9392 5.64648 13.5856 5.64648C12.1768 5.64648 11.0442 6.53039 10.4365 8.29834H14.884V11.0054H9.93924V12.8838H14.884V15.5913H10.4088C11.0166 17.4974 12.1768 18.3535 13.6685 18.3535C14.9945 18.3535 15.8232 17.6075 16.6519 16.5854L19.1934 19.0166C17.7845 20.8398 16.0718 22 13.5856 22C9.85635 22 7.23205 19.6245 6.2652 15.5913H4V12.8838H5.87845V11.917C5.87845 11.6131 5.87844 11.3092 5.90607 11.0054H4V8.29834H6.32043C7.31491 4.43094 9.93922 2 13.6133 2C16.2652 2 17.8122 3.10495 19.1657 4.79004Z"
    />
  </svg>
);
