import { SvgSymbol } from '../symbol-factory';

export const Pin: SvgSymbol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M12 1C8.13401 1 5 4.13401 5 8C5 8.67929 5.18946 9.57394 5.5471 10.671L5.73984 11.2364C5.84312 11.5274 5.95668 11.831 6.08021 12.1469L6.34043 12.795L6.62662 13.4756L6.93818 14.1883L7.27451 14.9328C7.53345 15.4976 7.80958 16.0811 8.10063 16.6798C8.59735 17.7017 9.12019 18.7332 9.64955 19.7452L10.363 21.0903L11.128 22.4896C11.5102 23.1701 12.4898 23.1701 12.872 22.4896L13.2938 21.7243L13.637 21.0903L13.8197 20.7495C14.5283 19.422 15.2371 18.0423 15.8994 16.6798C16.1904 16.0811 16.4665 15.4976 16.7255 14.9328L17.0618 14.1883L17.3734 13.4756L17.6596 12.795L17.9198 12.1469C18.0433 11.831 18.1569 11.5274 18.2602 11.2364L18.4529 10.671C18.8105 9.57394 19 8.67929 19 8C19 4.13401 15.866 1 12 1ZM12 3C14.7614 3 17 5.23858 17 8C17 8.35111 16.9078 8.84417 16.7324 9.45853L16.6032 9.88585C16.5796 9.95999 16.555 10.0356 16.5295 10.1126L16.3644 10.5913L16.1761 11.1025L15.9652 11.6449L15.7323 12.2172L15.478 12.8179L15.2028 13.4457L14.9074 14.0993C14.655 14.6499 14.3853 15.2198 14.1006 15.8055L13.6048 16.8113C13.2694 17.483 12.9251 18.1552 12.5778 18.8191L11.999 19.911L11.9447 19.8075C11.2471 18.5009 10.5496 17.1431 9.89937 15.8055C9.61468 15.2198 9.34496 14.6499 9.09257 14.0993L8.79716 13.4457L8.522 12.8179L8.26768 12.2172L8.03478 11.6449L7.82389 11.1025L7.6356 10.5913L7.47051 10.1126C7.44496 10.0356 7.4204 9.95999 7.39685 9.88585L7.26765 9.45853C7.0922 8.84417 7 8.35111 7 8C7 5.23858 9.23858 3 12 3Z" />
    <path d="M12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" />
  </svg>
);
