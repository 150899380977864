import { forwardRef } from 'react';
import * as RxSelect from '@radix-ui/react-select';
import { Icon } from '../../../symbols';
import { ClassName, cn } from '../../../../utils';
import styles from './select.module.css';

export interface SelectProps {
  className?: ClassName;
  placeholder?: React.ReactNode;
  invert?: boolean;
  children?: React.ReactNode;
  value?: string;
  defaultValue?: string;
  onValueChange?(value: string): void;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?(open: boolean): void;
  dir?: 'ltr' | 'rtl';
  id?: string;
  name?: string;
  autoComplete?: boolean;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      className,
      placeholder,
      children,
      invert,
      autoComplete,
      error,
      id,
      ...props
    },
    ref,
  ) => {
    return (
      <RxSelect.Root {...props} autoComplete={autoComplete ? 'on' : 'off'}>
        <RxSelect.Trigger
          id={id}
          className={cn(
            styles['select'],
            invert && styles['invert'],
            error && styles['error'],
            className,
          )}
        >
          <RxSelect.Value placeholder={placeholder} ref={ref} />
          <RxSelect.Icon asChild>
            <div>
              <Icon name="dropdown" />
            </div>
          </RxSelect.Icon>
        </RxSelect.Trigger>
        <RxSelect.Portal>
          <RxSelect.Content
            className={cn(styles['dropdown'], invert && styles['invert'])}
          >
            <RxSelect.ScrollUpButton className={styles['scroll-button']}>
              <Icon name="chevron-up" />
            </RxSelect.ScrollUpButton>
            <RxSelect.Viewport className={styles.list}>
              {children}
            </RxSelect.Viewport>
            <RxSelect.ScrollDownButton className={styles['scroll-button']}>
              <Icon name="chevron-down" />
            </RxSelect.ScrollDownButton>
          </RxSelect.Content>
        </RxSelect.Portal>
      </RxSelect.Root>
    );
  },
);
