import { SvgSymbol } from '../symbol-factory';

export const Upload: SvgSymbol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M12 4C15.5686 4 18.4615 6.89293 18.4615 10.4615C18.4615 10.4928 18.4613 10.5241 18.4609 10.5553C18.7584 10.4935 19.0678 10.4615 19.3846 10.4615C21.9336 10.4615 24 12.5279 24 15.0769C24 17.5588 22.0409 19.5834 19.5848 19.6883L19.3846 19.6923H13.8462V15.076L15.6923 15.0769C16.5147 15.0769 16.9265 14.0827 16.345 13.5012L12.6527 9.80889C12.2922 9.44841 11.7078 9.44841 11.3473 9.80889L7.65499 13.5012C7.07349 14.0827 7.48532 15.0769 8.30769 15.0769L10.1538 15.076V19.6923H4.61538C2.06638 19.6923 0 17.6259 0 15.0769C0 12.5279 2.06638 10.4615 4.61538 10.4615C4.93255 10.4615 5.24223 10.4935 5.54139 10.5544L5.53846 10.4615C5.53846 6.89293 8.43139 4 12 4Z" />
  </svg>
);
