import { Link, LinkProps } from '@remix-run/react';
import { Icon } from '../symbols';
import { cn } from '../../utils';
import styles from './quick-link.module.css';

type QuickLinkProps = LinkProps &
  React.RefAttributes<HTMLAnchorElement> & {
    as?: React.ElementType;
  };

export function QuickLink({
  children,
  as: AsLink = Link,
  className,
  ...props
}: QuickLinkProps) {
  return (
    <AsLink {...props} className={cn(styles['link'], className)}>
      {children}{' '}
      <Icon
        name="arrow-right"
        color="var(--colors-calypso_blue)"
        className="arrow"
      />
    </AsLink>
  );
}
