import { PropsWithChildren } from 'react';
import styles from './blocks.module.css';

export function MessageBlock(props: PropsWithChildren) {
  return <div {...props} className={styles['message-block']} />;
}

export function BottomBlock(props: PropsWithChildren) {
  return <div {...props} className={styles['bottom-block']} />;
}
