import { SvgSymbol } from '../symbol-factory';

export const Edit: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 16 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 12.838 11.228 L 13.192 10.874 L 12.131 9.814 L 10.188 7.871 L 9.127 6.811 L 8.773 7.164 L 8.066 7.871 L 1.833 14.102 C 1.508 14.428 1.27 14.831 1.139 15.272 L 0.031 19.039 C -0.047 19.301 0.025 19.586 0.222 19.78 C 0.419 19.974 0.701 20.046 0.963 19.971 L 4.728 18.864 C 5.169 18.732 5.573 18.494 5.898 18.169 L 12.131 11.938 L 12.838 11.228 Z M 5.006 16.495 L 4.721 17.206 C 4.596 17.303 4.455 17.374 4.305 17.421 L 1.858 18.141 L 2.578 15.698 C 2.622 15.545 2.697 15.404 2.794 15.282 L 3.504 14.997 L 3.504 15.998 C 3.504 16.273 3.73 16.499 4.005 16.499 L 5.006 16.499 L 5.006 16.495 Z M 11.349 4.587 L 10.898 5.04 L 10.191 5.747 L 9.834 6.101 L 10.895 7.161 L 12.838 9.104 L 13.899 10.164 L 14.252 9.811 L 14.96 9.104 L 15.413 8.65 C 16.196 7.868 16.196 6.601 15.413 5.819 L 14.184 4.587 C 13.401 3.804 12.134 3.804 11.352 4.587 L 11.349 4.587 Z M 9.865 9.842 L 5.36 14.346 C 5.166 14.54 4.847 14.54 4.653 14.346 C 4.459 14.152 4.459 13.833 4.653 13.639 L 9.158 9.135 C 9.352 8.941 9.671 8.941 9.865 9.135 C 10.059 9.329 10.059 9.648 9.865 9.842 Z" />
  </svg>
);
