import { PropsWithChildren, useEffect } from 'react';
import styles from './ebbot.module.css';

declare global {
  interface Window {
    Ebbot: { botId: string; openChatWindow: () => void };
  }
}

type EbbotProps = {
  botId?: string;
};

type ClassName = string | false | (string[] & string) | undefined;

const scriptId = 'init-js-widget';

export function Ebbot({ botId }: EbbotProps) {
  useEffect(() => {
    if (!window || !document || document.getElementById(scriptId)) return;
    if (!botId && !window.Ebbot.botId) return;
    window.Ebbot = window.Ebbot || { botId };

    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://ebbot-v2.storage.googleapis.com/ebbot-web/init.js?t=${cachebust()}`;
    document.body.appendChild(script);
  }, [botId]);

  return null;
}

function cachebust() {
  return (Math.random() + 1).toString(36).substring(7);
}

export function EbbotLink({
  children,
  className,
}: PropsWithChildren<{ className?: ClassName }>) {
  const openChat = () => window.Ebbot.openChatWindow();
  return (
    <span
      className={[styles.link, className].flat().filter(Boolean).join(' ')}
      onClick={openChat}
    >
      {children}
    </span>
  );
}
