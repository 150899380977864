import 'reflect-metadata';
import { Layout, layoutLoader } from './app/layout';
import { PageLayout, PageLoader } from './app/page';
import { BankIdError } from './app/routes/bankid/error';
import { BankIdRoute } from './app/routes/bankid/init';
import { BankIdLocal, BankIdLocalLoader } from './app/routes/bankid/local';
import { BankIdOther, BankIdOtherLoader } from './app/routes/bankid/other';
import { HomeRoute } from './app/routes/home';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './i18n';
import { OpenAPI } from '@lhb/api/idp';
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './appinsight';
import { BankIdCheck, bankIdCheckLoader } from './app/routes/bankid/check';
import { ErrorRoute } from './app/routes/error';
import {
  contactInfoAction,
  contactInfoLoader,
  ContactInfoPage,
} from './app/routes/contactinfo';

declare global {
  interface Window {
    config?: {
      baseUrl: string;
      aiKey?: string;
      autostart?: boolean;
    };
  }
}

OpenAPI.BASE = window.config?.baseUrl ?? OpenAPI.BASE;

const router = createBrowserRouter([
  {
    path: '/',
    element: <PageLayout />,
    loader: PageLoader,

    children: [
      {
        path: '/',
        element: <Layout />,
        loader: layoutLoader,
        errorElement: <ErrorRoute />,
        children: [
          {
            path: '/',
            element: <HomeRoute />,
          },
          {
            path: 'bankid',
            element: <BankIdRoute />,
            ErrorBoundary: BankIdError,
            children: [
              {
                path: 'other',
                element: <BankIdOther />,
                loader: BankIdOtherLoader,
              },
              {
                path: 'local',
                element: <BankIdLocal />,
                loader: BankIdLocalLoader,
              },
              {
                path: 'check/:requestId',
                element: <BankIdCheck />,
                loader: bankIdCheckLoader,
              },
            ],
          },
        ],
      },
      {
        path: 'contact-info/:requestId',
        element: <ContactInfoPage />,
        loader: contactInfoLoader,
        action: contactInfoAction,
      },
      {
        path: 'error',
        element: <ErrorRoute />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
  {
    onRecoverableError(error, errorInfo) {
      console.warn('Recoverable error:', error, errorInfo);
    },
  },
);
root.render(
  <StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => <h1>Testing application insight</h1>}
        appInsights={reactPlugin}
      >
        <RouterProvider router={router} />
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  </StrictMode>,
);
