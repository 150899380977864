import { SvgSymbol } from '../symbol-factory';

export const Attachment: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 17 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 14.356 5.035 C 13.338 4.038 11.69 4.038 10.672 5.035 L 3.161 12.398 C 1.451 14.069 1.451 16.784 3.161 18.456 C 4.871 20.128 7.639 20.128 9.349 18.456 L 15.295 12.627 C 15.663 12.267 16.258 12.267 16.622 12.627 C 16.986 12.988 16.99 13.571 16.622 13.928 L 10.676 19.759 C 8.234 22.153 4.276 22.153 1.835 19.759 C -0.608 17.367 -0.613 13.487 1.83 11.093 L 9.346 3.732 C 11.095 2.017 13.933 2.017 15.687 3.732 C 17.44 5.445 17.436 8.226 15.687 9.939 L 8.492 16.988 C 7.256 18.199 5.22 18.088 4.128 16.75 C 3.197 15.607 3.287 13.958 4.344 12.923 L 10.286 7.106 C 10.653 6.745 11.248 6.745 11.612 7.106 C 11.976 7.467 11.98 8.049 11.612 8.405 L 5.674 14.226 C 5.294 14.598 5.259 15.189 5.595 15.599 C 5.987 16.078 6.719 16.12 7.162 15.683 L 14.356 8.639 C 15.373 7.643 15.373 6.028 14.356 5.031 L 14.356 5.035 Z" />
  </svg>
);
