import { SvgSymbol } from '../symbol-factory';

export const BankId: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 8.316 12.522 L 9.378 5.841 C 8.958 5.841 8.244 5.841 8.244 5.841 C 7.713 5.841 7.032 5.544 6.831 4.998 C 6.768 4.812 6.615 4.179 7.491 3.561 C 7.803 3.342 8.004 3.102 8.043 2.916 C 8.085 2.724 8.034 2.556 7.899 2.427 C 7.707 2.241 7.329 2.136 6.846 2.136 C 6.036 2.136 5.466 2.604 5.409 2.94 C 5.37 3.189 5.562 3.39 5.73 3.519 C 6.237 3.897 6.357 4.443 6.045 4.956 C 5.721 5.487 5.025 5.832 4.278 5.841 C 4.278 5.841 3.54 5.841 3.12 5.841 C 3.024 6.492 1.449 16.467 1.329 17.253 L 7.578 17.253 C 7.635 16.908 7.923 15.021 8.316 12.522 Z" />
    <path d="M 0.993 19.566 L 3.552 19.566 C 4.647 19.566 4.911 20.121 4.83 20.625 C 4.767 21.036 4.485 21.342 4.002 21.543 C 4.614 21.774 4.854 22.137 4.767 22.707 C 4.653 23.421 4.035 23.952 3.225 23.952 L 0.309 23.952 L 0.993 19.566 Z M 2.685 21.381 C 3.183 21.381 3.417 21.117 3.468 20.802 C 3.513 20.466 3.363 20.235 2.865 20.235 L 2.421 20.235 L 2.244 21.381 L 2.685 21.381 Z M 2.412 23.271 C 2.928 23.271 3.225 23.061 3.297 22.635 C 3.354 22.266 3.144 22.047 2.646 22.047 L 2.148 22.047 L 1.956 23.277 L 2.412 23.277 L 2.412 23.271 Z" />
    <path d="M 8.358 23.985 C 7.689 24.033 7.368 23.958 7.209 23.67 C 6.855 23.886 6.462 24 6.045 24 C 5.289 24 5.025 23.607 5.097 23.172 C 5.127 22.965 5.247 22.764 5.442 22.596 C 5.859 22.233 6.888 22.185 7.29 21.912 C 7.32 21.606 7.2 21.495 6.822 21.495 C 6.381 21.495 6.012 21.639 5.376 22.071 L 5.529 21.075 C 6.075 20.685 6.606 20.499 7.215 20.499 C 7.995 20.499 8.685 20.82 8.559 21.672 L 8.406 22.635 C 8.349 22.971 8.364 23.076 8.742 23.085 L 8.358 23.985 Z M 7.2 22.464 C 6.846 22.692 6.189 22.65 6.117 23.118 C 6.084 23.334 6.219 23.493 6.438 23.493 C 6.645 23.493 6.903 23.406 7.113 23.262 C 7.095 23.181 7.104 23.1 7.128 22.947 L 7.2 22.464 Z" />
    <path d="M 9.603 20.553 L 10.935 20.553 L 10.863 20.997 C 11.289 20.634 11.61 20.499 12.027 20.499 C 12.774 20.499 13.119 20.955 12.999 21.702 L 12.654 23.943 L 11.322 23.943 L 11.61 22.089 C 11.667 21.753 11.562 21.591 11.304 21.591 C 11.094 21.591 10.902 21.702 10.719 21.951 L 10.413 23.934 L 9.081 23.934 L 9.603 20.553 Z" />
    <path d="M 14.043 19.566 L 15.378 19.566 L 15.039 21.72 L 16.317 20.553 L 17.964 20.553 L 16.326 21.999 L 17.64 23.943 L 15.963 23.943 L 14.952 22.368 L 14.937 22.368 L 14.694 23.934 L 13.362 23.934 L 14.043 19.566 Z" />
    <path d="M 18.759 19.566 L 20.292 19.566 L 19.617 23.943 L 18.084 23.943 L 18.759 19.566 Z" />
    <path d="M 21.039 19.566 L 23.232 19.566 C 24.927 19.566 25.416 20.796 25.257 21.816 C 25.104 22.812 24.318 23.943 22.83 23.943 L 20.358 23.943 L 21.039 19.566 Z M 22.461 22.899 C 23.208 22.899 23.619 22.53 23.739 21.753 C 23.826 21.174 23.649 20.604 22.824 20.604 L 22.413 20.604 L 22.059 22.899 L 22.461 22.899 Z" />
    <path d="M 16.719 0 L 10.332 0 L 9.48 5.406 L 10.566 5.406 C 11.16 5.406 11.721 5.133 11.964 4.74 C 12.045 4.611 12.075 4.497 12.075 4.395 C 12.075 4.17 11.922 3.999 11.769 3.888 C 11.352 3.576 11.265 3.246 11.265 3.012 C 11.265 2.964 11.265 2.925 11.271 2.883 C 11.361 2.313 12.132 1.695 13.152 1.695 C 13.761 1.695 14.229 1.839 14.508 2.106 C 14.757 2.337 14.856 2.667 14.781 3.012 C 14.694 3.42 14.283 3.759 14.052 3.927 C 13.434 4.362 13.515 4.74 13.554 4.851 C 13.68 5.19 14.172 5.406 14.55 5.406 L 16.203 5.406 C 16.203 5.406 16.203 5.406 16.203 5.412 C 18.453 5.43 19.659 6.465 19.281 8.877 C 18.927 11.118 17.208 12.081 15.159 12.096 L 14.349 17.268 L 15.546 17.268 C 20.598 17.268 24.726 14.025 25.539 8.898 C 26.541 2.538 22.5 0 16.719 0 Z" />
  </svg>
);
