import { SvgSymbol } from '../symbol-factory';

export const Piechart: SvgSymbol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <path d="M53.4667 33.871C54.2395 33.871 54.8667 34.4996 54.8667 35.2742C54.8667 36.0488 54.2395 36.6774 53.4667 36.6774H35.7333V58.1515C47.7099 57.4265 57.2 47.4607 57.2 35.2742C57.2 34.4996 57.8272 33.871 58.6 33.871C59.3728 33.871 60 34.4996 60 35.2742C60 49.4823 48.5088 61 34.3333 61C33.5605 61 32.9333 60.3714 32.9333 59.5968V35.2742C32.9333 34.4996 33.5605 33.871 34.3333 33.871H53.4667ZM29.6667 3C36.5649 3 43.0339 5.74284 47.8153 10.5353C48.3623 11.0826 48.3623 11.9713 47.8153 12.5185L31.0667 29.3077V47.5281C31.0667 48.3027 30.4395 48.9313 29.6667 48.9313C28.8939 48.9313 28.2667 48.3027 28.2667 47.5281V28.7258C28.2667 28.3535 28.4141 27.9961 28.6773 27.7342L44.8147 11.5559C40.6772 7.88323 35.3376 5.80645 29.6667 5.80645C17.0377 5.80645 6.8 16.0678 6.8 28.7258C6.8 41.3838 17.0377 51.6452 29.6667 51.6452C30.4395 51.6452 31.0667 52.2738 31.0667 53.0484C31.0667 53.823 30.4395 54.4516 29.6667 54.4516C15.4912 54.4516 4 42.9339 4 28.7258C4 14.5177 15.4912 3 29.6667 3ZM52.482 12.4063C57.2635 17.1988 60 23.6826 60 30.5968C60 31.3714 59.3728 32 58.6 32H34.3333C33.0864 32 32.4611 30.4883 33.344 29.6052L46.9427 15.9733C47.4905 15.4251 48.3763 15.4251 48.9232 15.9733C49.4701 16.5215 49.4701 17.4093 48.9232 17.9575L37.7139 29.1935H57.158C56.8173 23.5619 54.4392 18.3354 50.5033 14.3905C49.9555 13.8423 49.9555 12.9535 50.5033 12.4063C51.0493 11.8581 51.936 11.8581 52.482 12.4063Z" />
  </svg>
);
