import { SvgSymbol } from '../symbol-factory';

export const CrossSmall: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 10 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 1.707 7.293 L 5 10.585 L 8.293 7.293 C 8.653 6.932 9.221 6.904 9.613 7.209 L 9.707 7.293 C 10.098 7.684 10.098 8.317 9.707 8.707 L 6.415 12 L 9.707 15.293 C 10.098 15.684 10.098 16.317 9.707 16.707 C 9.317 17.098 8.683 17.098 8.293 16.707 L 5 13.415 L 1.707 16.707 C 1.347 17.068 0.779 17.096 0.387 16.791 L 0.293 16.707 C -0.098 16.317 -0.098 15.684 0.293 15.293 L 3.585 12 L 0.293 8.707 C -0.098 8.317 -0.098 7.684 0.293 7.293 C 0.683 6.902 1.317 6.902 1.707 7.293 Z" />
  </svg>
);
