import { isPending } from './pending';
import {
  CheckData,
  CheckErrorCodeStatusKeys,
  CheckResponseStatusKeys,
} from './types';
import {
  BankIdService,
  CheckResponseStatus,
  ErrorCodeStatus,
} from '@lhb/api/idp';

export async function doCheck(requestId: string): Promise<CheckData> {
  const result = await BankIdService.check({ requestId });
  if (!result.qrCode || typeof result.status === 'undefined')
    throw new Error('Invalid response from server');

  if (isPending(result.status))
    return {
      status: getStatusKey(result.status),
      qrCode: result.qrCode,
      state: 'pending',
    };
  if (result.status === CheckResponseStatus.Complete)
    return { state: 'success', status: getStatusKey(result.status) };
  return {
    state: 'failed',
    status: result.errorCode
      ? getErrorKey(result.errorCode)
      : getStatusKey(result.status),
  };
}

function getStatusKey(value: CheckResponseStatus): CheckResponseStatusKeys {
  const keys = Object.keys(CheckResponseStatus) as CheckResponseStatusKeys[];
  for (const key of keys) {
    if (CheckResponseStatus[key] === value) {
      return key;
    }
  }
  return 'Unknown'; // Default value if no match is found
}

function getErrorKey(value: ErrorCodeStatus): CheckErrorCodeStatusKeys {
  const keys = Object.keys(ErrorCodeStatus) as CheckErrorCodeStatusKeys[];
  for (const key of keys) {
    if (ErrorCodeStatus[key] === value) {
      return key;
    }
  }
  return 'Unknown'; // Default value if no match is found
}
