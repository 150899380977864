import { SvgSymbol } from '../symbol-factory';

export const Plus: SvgSymbol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M12 3C12.5523 3 13 3.44772 13 4V11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H13V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V13H4C3.44771 13 3 12.5523 3 12C3 11.4477 3.44771 11 4 11H11V4C11 3.44772 11.4477 3 12 3Z" />
  </svg>
);
