import {
  DefaultValues,
  FieldErrors,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { getFields } from '../extractors/field';
import {
  FetcherWithComponents,
  FormComponent,
  FormErrors,
  FormSchema,
  FormValues,
  ObjectFromSchema,
  UseActionData,
  UseNavigation,
  UseSubmit,
} from '../types/form';
import { Button } from '../components/button';
import { Field } from '../components/field';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useMemo, useRef } from 'react';
import { ButtonGroup, HTMLFormMethod, Form as UIForm } from '@lhb/ui';
import { FormMessage } from '../components/form-message';
import { useTranslation } from 'react-i18next';

type FormFactoryProps = {
  component: FormComponent;
  useSubmit: UseSubmit;
  useNavigation: UseNavigation;
  useActionData: UseActionData;
  i18n: {
    defaultNs: string;
    formNs?: string;
    globalError?: string;
  };
};

type FormProps<Schema extends FormSchema> = {
  schema: Schema;
  resetable?: boolean;
  component?: FormComponent;
  method?: HTMLFormMethod;
  fetcher?: FetcherWithComponents;
  mode?: 'onSubmit' | 'onBlur' | 'onChange' | 'onTouched' | 'all';
  reValidateMode?: 'onChange' | 'onBlur' | 'onSubmit';
  replace?: boolean;
  preventScrollReset?: boolean;
  navigate?: boolean;
  fetcherKey?: string;
  flushSync?: boolean;
  submitLabel?: React.ReactNode;
  i18nNs?: string;
  invert?: boolean;
  errors?: FormErrors<ObjectFromSchema<Schema>>;
  values?: FormValues<ObjectFromSchema<Schema>>;
};

export function createForm({
  component: DefaultComponent,
  i18n,
  useSubmit,
  useNavigation,
  useActionData,
}: FormFactoryProps) {
  return function Form<Schema extends FormSchema>({
    component = DefaultComponent,
    fetcher,
    schema,
    method = 'post',
    reValidateMode = 'onBlur',
    mode = 'onSubmit',
    replace,
    preventScrollReset,
    navigate,
    resetable,
    fetcherKey,
    flushSync,
    submitLabel,
    invert,
    errors: errorsProp,
    values: valuesProp,
    i18nNs = i18n.defaultNs,
  }: FormProps<Schema>) {
    i18n.formNs = i18nNs;
    const Component = fetcher?.Form ?? component;
    const formRef = useRef<HTMLFormElement>(null);
    const fields = getFields(schema);
    const navigationSubmit = useSubmit();
    const { t } = useTranslation(i18nNs);
    const submit = fetcher?.submit ?? navigationSubmit;
    const navigationTransition = useNavigation();
    const transition = fetcher ?? navigationTransition;
    const navigationActionData = useActionData();
    const actionData = fetcher ? fetcher.data : navigationActionData;

    const actionErrors = actionData?.errors as FormErrors<Schema>;
    const actionValues = actionData?.values as FormValues<Schema>;

    const errors: FieldErrors<Schema> = useMemo(
      () => ({ ...errorsProp, ...actionErrors }) as FieldErrors<Schema>,
      [errorsProp, actionErrors],
    );

    const values: FormValues<Schema> = useMemo(
      () => ({ ...valuesProp, ...actionValues }),
      [valuesProp, actionValues],
    );

    const form = useForm<Schema>({
      resolver: classValidatorResolver(schema),
      defaultValues: values as DefaultValues<Schema>,
      reValidateMode,
      mode,
      errors,
    });

    const { formState } = form;
    const { errors: formErrors } = formState;

    const hasErrors = Object.keys(formErrors).length > 0;

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
      form.handleSubmit(() => {
        if (!formRef.current) return;

        return submit(formRef.current, {
          method,
          replace,
          preventScrollReset,
          navigate,
          fetcherKey,
          flushSync,
        });
      })(event);
    };

    const globalError = !i18n.globalError
      ? undefined
      : t(i18n.globalError) !== i18n.globalError
        ? t(i18n.globalError)
        : t(i18n.globalError, { ns: i18n.defaultNs });

    const resetLabel =
      t('RESET') !== 'RESET' ? t('RESET') : t('RESET', { ns: i18n.defaultNs });

    return (
      <FormProvider {...form}>
        <UIForm
          as={Component}
          ref={formRef}
          method={method}
          onSubmit={onSubmit}
        >
          {fields.map((field) => (
            <Field
              key={field.name}
              invert={invert}
              {...field}
              i18n={i18n as Required<typeof i18n>}
            />
          ))}
          <ButtonGroup>
            <Button label={submitLabel} />
            {resetable && <Button type="reset" label={resetLabel} />}
            {hasErrors && globalError && (
              <FormMessage>{globalError}</FormMessage>
            )}
          </ButtonGroup>
        </UIForm>
      </FormProvider>
    );
  };
}
