import { CSSProperties, PropsWithChildren } from 'react';
import styles from './panel.module.css';
import { cn } from '../../utils';
import { Pictogram, Pictograms } from '../symbols';

type PanelProps = PropsWithChildren<{
  variant?: 'default' | 'info' | 'timeline';
  as?: React.ElementType;
  className?: string;
  style?: CSSProperties;
  pictogram?: Pictograms;
  title?: React.ReactNode;
}>;

export function Panel({
  as,
  children,
  className,
  title,
  pictogram,
  variant,
}: PanelProps) {
  const Comp = as || 'div';
  return (
    <Comp
      className={cn(styles.container, variant && styles[variant], className)}
    >
      {title && (
        <div className={styles['header']}>
          {pictogram && (
            <Pictogram
              name={pictogram}
              size="3.5rem"
              className={styles['pictogram']}
            />
          )}
          <h3>{title}</h3>
        </div>
      )}
      {children}
    </Comp>
  );
}
