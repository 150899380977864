import { forwardRef } from 'react';
import { cn } from '../../utils';
import styles from './form.module.css';

type LowerCaseFormMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';
type UpperCaseFormMethod = Uppercase<LowerCaseFormMethod>;
export type HTMLFormMethod = LowerCaseFormMethod | UpperCaseFormMethod;

export type BaseFormProps = {
  discover?: 'render' | 'none';
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  replace?: boolean;
  preventScrollReset?: boolean;
  navigate?: boolean;
  reloadDocument?: boolean;
  fetcherKey?: string;
  flushSync?: boolean;
  children: React.ReactNode;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
  viewTransition?: boolean;
  method?: HTMLFormMethod;
  noValidate?: boolean;
};

export type FormComponent = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<BaseFormProps & { method?: HTMLFormMethod }> &
    React.RefAttributes<HTMLFormElement>
>;

export type FormProps = BaseFormProps & {
  as?: FormComponent;
};

export const Form = forwardRef<HTMLFormElement, FormProps>(
  ({ as, noValidate = true, ...props }, ref) => {
    const Comp = as || 'form';
    return (
      <Comp
        ref={ref}
        noValidate={noValidate}
        {...props}
        className={cn(styles.form, props.className)}
      />
    );
  },
);
