import { PropsWithChildren } from 'react';
import { Text } from '../../components/typography';
import styles from './sidebar-layout.module.css';

type SidebarLayoutProps = PropsWithChildren<{
  sidebar?: React.ReactNode;
  title?: string;
}>;
export function SidebarLayout({
  children,
  title,
  sidebar,
}: SidebarLayoutProps) {
  if (!sidebar) return children;
  return (
    <>
      <div className={styles['container']}>
        {title && (
          <Text asChild variant="title" className={styles.title}>
            <h1>{title}</h1>
          </Text>
        )}
        {children && <main className={styles['content']}>{children}</main>}

        <aside className={styles['sidebar']}>{sidebar}</aside>
      </div>
    </>
  );
}
