import { FC, useEffect } from 'react';

const isDesktopSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return (
    ua.includes('safari') && !ua.includes('chrome') && !ua.includes('mobile')
  );
};

type SafariFlexGridFixProps = {
  parentId: string;
};

export const SafariFlexGridFix: FC<SafariFlexGridFixProps> = ({ parentId }) => {
  useEffect(() => {
    if (!isDesktopSafari()) return;

    const parentElement = document.getElementById(parentId);
    const firstElement = parentElement?.firstElementChild as HTMLElement;
    const lastElement = parentElement?.lastElementChild as HTMLElement;
    const middleElement = parentElement?.children[1] as HTMLElement;

    if (parentElement && firstElement && middleElement && lastElement) {
      const parentHeight = parentElement.clientHeight;
      const firstHeight = firstElement.clientHeight;
      const lastHeight = lastElement.clientHeight;

      const middleHeight = parentHeight - firstHeight - lastHeight;
      middleElement.style.height = `${middleHeight}px`;
    }
  }, [parentId]);

  return null;
};
