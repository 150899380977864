import { PropsWithChildren } from 'react';
import style from './field-wrapper.module.css';
import { Label } from '../../../label';

export type FieldWrapperProps = PropsWithChildren<{
  id?: string;
  label?: string;
  required?: string;
}>;

export function FieldWrapper({
  id,
  label,
  children,
  required = '',
}: FieldWrapperProps) {
  return (
    <div className={style['field-wrapper']}>
      {!!label && <Label htmlFor={id}>{`${label} ${required}`}</Label>}
      {children}
    </div>
  );
}
