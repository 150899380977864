import { FieldsetHTMLAttributes, forwardRef } from 'react';
import style from './field-group.module.css';
import { cn } from '../../../../utils';

export const FieldGroup = forwardRef<
  HTMLFieldSetElement,
  FieldsetHTMLAttributes<HTMLFieldSetElement>
>(({ children, className, ...props }, ref) => (
  <fieldset ref={ref} className={cn(style.element, className)} {...props}>
    {children}
  </fieldset>
));
