import styles from './app.module.css';
import { FraudWarning } from './components/fraud-warning';
import { Panel } from '@lhb/ui';
import { convertCase } from '@lhb/utils';
import { Outlet, LoaderFunctionArgs } from 'react-router-dom';
import { ReturnUrlStore } from './helpers/return-url.store';

export async function layoutLoader({ request }: LoaderFunctionArgs) {
  const { searchParams } = new URL(request.url);
  const params = new URLSearchParams();

  for (const [name, value] of searchParams) {
    params.append(convertCase(name), value);
  }

  const returnUrl = params.get('returnUrl');

  if (returnUrl) {
    ReturnUrlStore.set(returnUrl);
  }

  if (!returnUrl && !ReturnUrlStore.get() && request.url !== '/error') {
    throw new Error('missing returnUrl');
  }

  return null;
}

export function Layout() {
  return (
    <div className={styles['container']}>
      <Panel as="main" className={styles['content']}>
        <Outlet />
      </Panel>
      <Panel variant="info" className={styles['info-box']}>
        <FraudWarning />
      </Panel>
    </div>
  );
}
