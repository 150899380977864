import { SvgSymbol } from '../symbol-factory';

export const Document: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11 0L11.101 0.006L11.1568 0.012361C11.1884 0.0172036 11.2193 0.0236158 11.2497 0.0314174L11.2799 0.0399762C11.3109 0.0487322 11.3414 0.0592262 11.3711 0.071104C11.38 0.0749696 11.3891 0.0787342 11.398 0.0826309C11.4263 0.0946381 11.4537 0.108068 11.4803 0.122674C11.4907 0.128558 11.5013 0.134613 11.5118 0.140865C11.5399 0.157498 11.567 0.175549 11.5932 0.194845L11.6133 0.210127C11.6177 0.213539 11.6216 0.2166 11.6254 0.219689L11.7071 0.292893L17.7071 6.29289L17.772 6.366L17.7895 6.38617L17.8055 6.40736C17.8245 6.43297 17.8425 6.46009 17.8592 6.4881L17.8773 6.52005C17.8919 6.54634 17.9054 6.5737 17.9175 6.60173C17.9213 6.61094 17.925 6.61998 17.9287 6.62907C17.9408 6.65865 17.9513 6.68906 17.9603 6.72009L17.9683 6.75033C17.9764 6.78069 17.9828 6.81161 17.9877 6.84301C17.9893 6.85361 17.9908 6.86402 17.9921 6.87447C17.9926 6.87729 17.9929 6.88033 17.9933 6.88338L18 7V21C18 22.6569 16.6569 24 15 24H3C1.34315 24 0 22.6569 0 21V3C0 1.34315 1.34315 0 3 0H11ZM10 2H3C2.44772 2 2 2.44772 2 3V21C2 21.5523 2.44772 22 3 22H15C15.5523 22 16 21.5523 16 21V8H11C10.4477 8 10 7.55228 10 7V2ZM11 17C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H5C4.44772 19 4 18.5523 4 18C4 17.4477 4.44772 17 5 17H11ZM13 13C13.5523 13 14 13.4477 14 14C14 14.5523 13.5523 15 13 15H5C4.44772 15 4 14.5523 4 14C4 13.4477 4.44772 13 5 13H13ZM13 9C13.5523 9 14 9.44771 14 10C14 10.5523 13.5523 11 13 11H5C4.44772 11 4 10.5523 4 10C4 9.44771 4.44772 9 5 9H13ZM12 3.415V6H14.586L12 3.415Z" />
  </svg>
);
