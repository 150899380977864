import { ChangeEventHandler, forwardRef } from 'react';
import styles from './radio.module.css';
import { Label } from '../../../label';

export type RadioProps = {
  name?: string;
  id?: string;
  label?: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
};

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ disabled, label, ...props }, ref) => {
    return (
      <Label
        htmlFor={props.id}
        aria-disabled={disabled}
        className={styles.label}
      >
        <input
          className={styles.element}
          type="radio"
          ref={ref}
          disabled={disabled}
          aria-disabled={disabled}
          {...props}
        />
        <div className={styles.radio} />
        {label}
      </Label>
    );
  },
);
