import { SvgSymbol } from '../symbol-factory';

export const Farm: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 16.866 12.361 L 18.266 10.347 L 18.266 14.385 L 16.866 12.361 Z M 17.892 9.542 L 14.906 9.542 L 16.399 11.69 L 17.892 9.542 Z M 14.532 10.348 L 14.532 14.404 L 15.932 12.362 L 14.532 10.348 Z M 4.475 23 L 0 23 L 0 22.111 L 4.105 22.111 L 5.417 20.696 L 0 20.697 L 0 19.787 L 6.23 19.787 L 6.23 19.819 L 7.572 18.372 L 0 18.372 L 0 17.461 L 8.41 17.461 L 8.41 17.467 L 9.768 16.003 L 0 16.003 L 0 15.18 L 2.417 15.18 L 2.417 14.027 C 1.9 13.937 1.504 13.458 1.504 12.874 L 1.504 8.188 C 1.504 7.384 2.107 6.734 2.852 6.734 C 3.597 6.734 4.2 7.385 4.2 8.188 L 4.2 12.874 C 4.2 13.503 3.74 14.012 3.163 14.041 L 3.163 15.18 L 6.515 15.18 L 6.515 14.027 C 5.998 13.937 5.602 13.458 5.602 12.874 L 5.602 8.188 C 5.602 7.384 6.205 6.734 6.95 6.734 C 7.695 6.734 8.298 7.385 8.298 8.188 L 8.298 12.874 C 8.298 13.503 7.838 14.012 7.261 14.041 L 7.261 15.18 L 10.799 15.18 L 10.798 5.148 L 16.655 1 L 21.999 5.138 L 21.999 16.003 L 20.367 16.003 L 13.88 23 L 12.687 23 L 19.175 16.003 L 17.248 16.003 L 10.761 23 L 9.567 23 L 16.054 16.003 L 14.112 16.003 L 7.626 23 L 6.433 23 L 12.92 16.003 L 10.96 16.003 L 4.475 23 Z M 15.652 6.734 L 17.146 6.734 L 17.146 4.72 L 15.652 4.72 L 15.652 6.734 Z M 13.786 15.18 L 14.919 15.18 L 16.399 13.033 L 17.892 15.18 L 19.012 15.18 L 19.012 8.737 L 13.786 8.737 L 13.786 15.18 Z M 21.999 16.439 L 15.917 23 L 17.11 23 L 22 17.726 L 22 16.438 L 21.999 16.439 Z M 19.056 23 L 20.25 23 L 21.999 21.113 L 21.999 19.826 L 19.056 23 Z" />
  </svg>
);
