import {
  CheckboxLabel,
  FieldGroup,
  FieldWrapper,
  FieldWrapperProps,
  Input,
  Multiline,
  Radio,
  Select,
} from '@lhb/ui';
import { FieldsetHTMLAttributes } from 'react';

export type FieldComponents = {
  input: typeof Input;
  multiline: typeof Multiline;
  checkbox: typeof CheckboxLabel;
  radio: typeof Radio;
  select: typeof Select;
};

export type FieldWrappers = {
  FieldGroup: React.ForwardRefExoticComponent<
    FieldsetHTMLAttributes<HTMLFieldSetElement>
  >;
  FieldWrapper: React.FC<FieldWrapperProps>;
};

export const fieldComponents: FieldComponents = {
  input: Input,
  multiline: Multiline,
  checkbox: CheckboxLabel,
  radio: Radio,
  select: Select,
};

export const fieldWrappers: FieldWrappers = {
  FieldGroup,
  FieldWrapper,
};
