import { SvgSymbol } from '../symbol-factory';

export const ChevronDown: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 10 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 1.707 9.293 C 1.316 8.902 0.683 8.902 0.293 9.293 C -0.098 9.683 -0.098 10.317 0.293 10.707 L 4.293 14.707 C 4.684 15.098 5.317 15.098 5.707 14.707 L 9.707 10.707 C 10.098 10.317 10.098 9.683 9.707 9.293 C 9.317 8.902 8.684 8.902 8.293 9.293 L 5 12.586 L 1.707 9.293 Z" />
  </svg>
);
