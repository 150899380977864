import { SvgSymbol } from '../symbol-factory';

export const Error: SvgSymbol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7628 20.9491L12.8828 1.98725C12.6904 1.68353 12.358 1.5 12.0001 1.5C11.6421 1.5 11.3098 1.68352 11.1174 1.98725L0.237382 20.9491C0.0551927 21.2683 0.0539117 21.6612 0.236099 21.9805C0.418289 22.2997 0.754431 22.4974 1.1201 22.5H22.8801C23.2458 22.4974 23.5819 22.2997 23.7641 21.9805C23.9463 21.6612 23.945 21.2684 23.7628 20.9491ZM10.75 18.4502C10.75 17.7598 11.3096 17.2002 12 17.2002C12.6904 17.2002 13.25 17.7598 13.25 18.4502C13.25 19.1406 12.6904 19.7002 12 19.7002C11.3096 19.7002 10.75 19.1406 10.75 18.4502ZM12.0249 7.5C11.5417 7.5 11.1499 7.89175 11.1499 8.375V15.625C11.1499 16.1082 11.5417 16.5 12.0249 16.5C12.5082 16.5 12.8999 16.1082 12.8999 15.625V8.375C12.8999 7.89175 12.5082 7.5 12.0249 7.5Z"
    />
  </svg>
);
