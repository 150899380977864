import { cn } from '../../utils';
import { Text } from '../typography';
import styles from './label.module.css';

export type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement>;
export function Label({ className, ...props }: LabelProps) {
  return (
    <Text
      asChild
      variant="body-bold"
      className={cn(styles['element'], className)}
    >
      <label {...props} />
    </Text>
  );
}
