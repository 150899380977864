import { PropsWithChildren } from 'react';
import { cn } from '../../utils';
import styles from './button-group.module.css';

type ButtonGroupProps = PropsWithChildren<{
  reverse?: boolean;
}>;

export function ButtonGroup({ children, reverse }: ButtonGroupProps) {
  return (
    <div className={cn(styles.container, reverse && styles.reverse)}>
      {children}
    </div>
  );
}
