import { SvgSymbol } from '../symbol-factory';

export const Chat: SvgSymbol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M16 18C16 17.4477 16.4477 17 17 17H21C21.5523 17 22 16.5523 22 16V6C22 5.44772 21.5523 5 21 5H3C2.44772 5 2 5.44772 2 6V16C2 16.5523 2.44772 17 3 17H12C12.2271 17 12.4474 17.0773 12.6247 17.2191L16 19.9194V18ZM3 19C1.34315 19 0 17.6569 0 16V6C0 4.34315 1.34315 3 3 3H21C22.6569 3 24 4.34315 24 6V16C24 17.6569 22.6569 19 21 19H18V22C18 22.8385 17.0301 23.3047 16.3753 22.7809L11.6492 19H3Z" />
  </svg>
);
