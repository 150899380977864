import { BackLink } from '../../components/back-link';
import sharedStyles from '../shared.module.css';
import { useBankIdCheck } from './hooks/check.hook';
import { ApiError, BankIdService, InitResponse } from '@lhb/api/idp';
import { Akka, cn } from '@lhb/ui';
import { Case, convertCase } from '@lhb/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { json, useLoaderData } from 'react-router-dom';
import { MessageBlock } from '../../components/blocks';
import { appInsights } from '../../../appinsight';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export async function BankIdOtherLoader() {
  try {
    console.log('Initiating BankId process');
    appInsights?.trackTrace({
      severityLevel: SeverityLevel.Information,
      message: 'Initiate BankId process',
    });
    const init = await BankIdService.init({ sameDevice: false });

    return json(init, { status: 200 });
  } catch (e) {
    console.log('BankId Init error', e);
    appInsights?.trackException({
      error: e as Error,
      severityLevel: SeverityLevel.Error,
      properties: { loader: 'BankId Init error' },
    });
    if (e instanceof ApiError) {
      throw new Response(e.message, {
        status: e.status,
        statusText: e.statusText,
      });
    }

    throw new Response('Internal Error', {
      status: 500,
      statusText: e as string,
    });
  }
}

export function BankIdOther() {
  const { t } = useTranslation('BANKID_LOCAL');
  const { requestId } = useLoaderData() as InitResponse;
  const { status, qrCode, state } = useBankIdCheck(requestId);
  const isUserSign = useMemo(
    () => status && ['UserSign', 'Complete'].includes(status),
    [status],
  );

  if (state === 'failed') throw new Error(status);

  return (
    <>
      <h1 className={sharedStyles['heading']}>
        {t('LOGIN_INTERNETBANK_TITLE')}
      </h1>

      {qrCode && !isUserSign ? (
        <QRCode className={sharedStyles['qr-code']} value={qrCode} />
      ) : (
        <>
          <Akka className={sharedStyles['qr-code']} />
        </>
      )}
      <MessageBlock>
        {status && (
          <p className={cn('text-bold', sharedStyles['subtitle'])}>
            {t(`BANKID_OTHER:${convertCase(status, Case.Snake).toUpperCase()}`)}
          </p>
        )}
      </MessageBlock>
      <BackLink label="Avbryt" />
    </>
  );
}
