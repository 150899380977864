import { FieldErrors, FieldValues, useFormContext } from 'react-hook-form';
import { Text } from '@lhb/ui';
import { useTranslation } from 'react-i18next';
import { FieldType, FieldPropsWithType } from '../types/field';
import { fieldComponents, fieldWrappers } from './field-components.map';
import { ComponentPropsWithRef } from 'react';

const { FieldWrapper } = fieldWrappers;

type FieldProps<Type extends FieldType> = FieldPropsWithType<Type> & {
  name: string;
  propertyType: 'string' | 'number' | 'boolean' | 'object' | 'array';
  invert?: boolean;
  i18n: {
    defaultNs: string;
    formNs: string;
  };
};
export function Field<Type extends FieldType>({
  fieldType,
  invert,
  required: isRequired,
  i18n,
  propertyType,
  ...props
}: FieldProps<Type>) {
  const Comp = fieldComponents[fieldType];
  const { t } = useTranslation(i18n.formNs);
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = getFieldError(props.name, errors);
  const id = `${props.name}-field`;
  const required = isRequired
    ? t('REQUIRED_FIELD', { ns: i18n.defaultNs })
    : undefined;

  if (fieldType === 'input' && 'hidden' in props && props.hidden) {
    return <input id={id} type="hidden" {...register(props.name)} />;
  }

  return (
    <FieldWrapper
      id={id}
      label={props.label && t(props.label)}
      required={required}
    >
      {/* @ts-expect-error: type issue */}
      <Comp
        id={id}
        {...(props as ComponentPropsWithRef<typeof Comp>)}
        invert={invert}
        {...register(props.name)}
        error={!!error}
        aria-required={isRequired}
      />
      <Text variant="body-small" color="danger">
        {t(error)}
      </Text>
    </FieldWrapper>
  );
}

function getFieldError(name: string, errors: FieldErrors<FieldValues>) {
  return errors[name] ? (errors[name].message as string) : '';
}
