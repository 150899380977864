import { SvgSymbol } from '../symbol-factory';

export const Mail: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 20 7.056 L 20 16.944 C 19.977 18.468 18.79 19.7 17.334 19.7 L 2.667 19.7 C 1.196 19.7 0 18.444 0 16.9 L 0 7.1 C 0 5.556 1.196 4.3 2.667 4.3 L 17.334 4.3 C 18.79 4.3 19.977 5.532 20 7.056 Z M 2.667 6.4 L 17.334 6.4 C 17.7 6.4 18 6.715 18 7.1 L 18 7.441 L 10.609 13.19 C 10.434 13.326 10.221 13.4 10 13.4 C 9.779 13.4 9.567 13.326 9.392 13.19 L 2 7.441 L 2 7.1 C 2 6.715 2.3 6.4 2.667 6.4 Z M 11.796 14.879 L 18 10.053 L 18 16.9 C 18 17.285 17.7 17.6 17.334 17.6 L 2.667 17.6 C 2.3 17.6 2 17.285 2 16.9 L 2 10.053 L 8.204 14.879 C 8.721 15.281 9.354 15.5 10 15.5 C 10.646 15.5 11.279 15.281 11.796 14.879 Z"
    />
  </svg>
);
