import type {
  ActionFunction,
  ActionFunctionArgs,
  RedirectFunction,
} from 'react-router-dom';
import { plainToInstance } from 'class-transformer';
import {
  validateOrReject,
  ValidationError,
  ValidatorOptions,
} from 'class-validator';
import { FormSchema } from '../types/form';
import { isFunction } from '@lhb/utils';

type Mutation<Schema, Result> =
  | ((values: Schema, context: ActionFunctionArgs) => Promise<Result> | Result)
  | ((values: Schema) => Promise<Result> | Result);

/**
 * @param schema - The schema of the form
 * @param validatorOptions - The options for class-validator
 * @param mutation - The mutation to be called
 * @param successPath - The path to redirect to after the mutation is successful
 */
type FormActionProps<Schema extends FormSchema, Result> = {
  schema: Schema;
  validatorOptions?: ValidatorOptions;
  mutation: Mutation<InstanceType<Schema>, Result>;
  successPath?:
    | string
    | ((result: Result) => string)
    | ((result: Result, context: ActionFunctionArgs) => string);
};

type CreateFormActionConfig = {
  redirect: RedirectFunction;
};

type CreateFormAction = <Schema extends FormSchema, Result>(
  props: FormActionProps<Schema, Result>,
) => ActionFunction;

/**
 * Creates a form action factory that can be used in a form
 * @param options - The configuration for the form action
 * @param options.redirect - The function to redirect to a new path
 * @returns A form action factory
 * @example
 * import {redirect} from 'react-router-dom';
 *
 * const formAction = createFormAction({
 *  redirect,
 * });
 *
 * const action = formAction({
 * schema: ContactInfoDto,
 * mutation: updateContactInformationApiFn,
 * successPath: '/success',
 * });
 */
export function createFormAction({
  redirect,
}: CreateFormActionConfig): CreateFormAction {
  return function formAction<Schema extends FormSchema, Result>({
    schema,
    mutation,
    successPath,
    validatorOptions,
  }: FormActionProps<Schema, Result>): ActionFunction {
    return async function action({ request, ...actionArgs }) {
      const context = { request, ...actionArgs };
      validatorOptions = validatorOptions || {
        whitelist: true,
        forbidNonWhitelisted: true,
      };

      try {
        const FormData = await request.formData();
        //@ts-expect-error: FormData.entries() returns an iterator
        const entries = FormData.entries() as IterableIterator<
          [keyof Schema, Schema[keyof Schema]]
        >;
        const values = Object.fromEntries(entries);
        const formValues = plainToInstance(
          schema,
          values,
        ) as InstanceType<Schema>;

        await validateOrReject(formValues, validatorOptions);

        const result = await mutation(formValues, context);

        if (!successPath) return result;
        if (isFunction(successPath))
          throw redirect(successPath(result, context));
        throw redirect(successPath);
      } catch (error) {
        if (Array.isArray(error) && error[0] instanceof ValidationError) {
          return { success: false, errors: error };
        }
        throw error;
      }
    };
  };
}
