import styles from '../error.module.css';
import { Button, cn } from '@lhb/ui';
import { Link } from '@remix-run/react';

type Error500Props = {
  link?: React.ElementType;
};

/**
 * @TODO: Convert to i18n compatible
 */
export function Error404({ link: AsLink = Link }: Error500Props) {
  return (
    <>
      <h1 className={cn('display', styles['heading'])}>
        Sidan kunde inte hittas
      </h1>
      <p className={cn('intro')}>
        Vi kan inte hitta sidan du letar efter. Det kan bero på att länken är
        felaktig eller att sidan har flyttats.
      </p>

      <Button asChild icon="return">
        <AsLink to="/">Tillbaka</AsLink>
      </Button>
    </>
  );
}
