import { CheckResponseStatus } from '@lhb/api/idp';

const pendingStatus: CheckResponseStatus[] = [
  CheckResponseStatus.OutstandingTransaction,
  CheckResponseStatus.Started,
  CheckResponseStatus.UserReq,
  CheckResponseStatus.UserSign,
];

export function isPending(status: CheckResponseStatus): boolean {
  return pendingStatus.includes(status);
}
