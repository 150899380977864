import { SvgSymbol } from '../symbol-factory';

export const LinkExternal: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 15 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 5 5.5 C 5.552 5.5 6 5.948 6 6.5 C 6 7.052 5.552 7.5 5 7.5 L 2 7.5 L 2 17.5 L 12 17.5 L 12 14.5 C 12 13.948 12.448 13.5 13 13.5 C 13.552 13.5 14 13.948 14 14.5 L 14 17.5 C 14 18.605 13.105 19.5 12 19.5 L 2 19.5 C 0.895 19.5 0 18.605 0 17.5 L 0 7.5 C 0 6.395 0.895 5.5 2 5.5 L 5 5.5 Z M 14 4.5 L 14.018 4.5 C 14.042 4.5 14.066 4.502 14.089 4.504 L 14 4.5 C 14.051 4.5 14.1 4.504 14.149 4.511 C 14.166 4.514 14.183 4.516 14.201 4.52 C 14.223 4.524 14.245 4.53 14.266 4.536 C 14.282 4.54 14.297 4.545 14.312 4.55 C 14.332 4.556 14.352 4.563 14.371 4.571 C 14.389 4.578 14.406 4.586 14.423 4.594 C 14.444 4.603 14.464 4.614 14.484 4.625 C 14.496 4.632 14.509 4.639 14.521 4.646 C 14.546 4.662 14.571 4.678 14.595 4.696 C 14.603 4.702 14.61 4.707 14.617 4.713 C 14.68 4.763 14.738 4.82 14.787 4.883 L 14.707 4.793 C 14.743 4.828 14.775 4.866 14.804 4.905 C 14.822 4.929 14.838 4.954 14.854 4.979 C 14.861 4.992 14.869 5.004 14.875 5.016 C 14.886 5.036 14.897 5.056 14.906 5.077 C 14.914 5.094 14.922 5.111 14.929 5.129 C 14.937 5.149 14.944 5.168 14.95 5.188 C 14.955 5.203 14.96 5.218 14.964 5.234 C 14.97 5.255 14.975 5.277 14.98 5.299 C 14.983 5.317 14.986 5.334 14.989 5.352 C 14.992 5.371 14.994 5.39 14.996 5.41 C 14.998 5.434 14.999 5.457 15 5.48 C 15 5.487 15 5.494 15 5.5 L 15 10.5 C 15 11.052 14.552 11.5 14 11.5 C 13.448 11.5 13 11.052 13 10.5 L 12.999 7.915 L 5.707 15.207 C 5.317 15.598 4.683 15.598 4.293 15.207 C 3.902 14.817 3.902 14.184 4.293 13.793 L 11.585 6.499 L 9 6.5 C 8.448 6.5 8 6.052 8 5.5 C 8 4.948 8.448 4.5 9 4.5 L 14 4.5 Z" />
  </svg>
);
