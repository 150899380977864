import { Case, convertCase } from '@lhb/utils';
import { BackLink } from '../../components/back-link';
import sharedStyles from '../shared.module.css';
import { useBankIdCheck } from './hooks/check.hook';
import { cn, Akka } from '@lhb/ui';
import { LoaderFunctionArgs, redirect, useLoaderData } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BottomBlock, MessageBlock } from '../../components/blocks';
import { appInsights } from '../../../appinsight';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export function bankIdCheckLoader({ params }: LoaderFunctionArgs) {
  console.log('Initiating BankId Check process');
  appInsights?.trackTrace({
    severityLevel: SeverityLevel.Information,
    message: 'Initiate BankId Check process',
  });

  const { requestId } = params;
  if (!requestId) throw redirect('/');
  return { requestId };
}

export function BankIdCheck() {
  const { t } = useTranslation('BANKID_LOCAL');
  const { requestId } = useLoaderData() as ReturnType<typeof bankIdCheckLoader>;
  const { status, state } = useBankIdCheck(requestId);

  if (state === 'failed') throw new Error(status);

  return (
    <>
      <h1 className={sharedStyles['heading']}>
        {t('LOGIN_INTERNETBANK_TITLE')}
      </h1>

      <Akka />

      <MessageBlock>
        {status && (
          <p className={cn('text-bold', sharedStyles['subtitle'])}>
            {t(convertCase(status, Case.Snake).toUpperCase())}
          </p>
        )}
      </MessageBlock>
      <BottomBlock>
        <BackLink label="Avbryt" />
      </BottomBlock>
    </>
  );
}
