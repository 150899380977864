import { CSSProperties, PropsWithChildren } from 'react';
import styles from './teaser.module.css';
import linkStyles from '../link/link.module.css';
import { cn } from '../../utils';
import { Icon, Pictogram, Pictograms } from '../symbols';
import { Link, LinkProps } from '@remix-run/react';

type TeaserProps = LinkProps &
  PropsWithChildren<{
    className?: string;
    style?: CSSProperties;
    pictogram?: Pictograms;
    title?: React.ReactNode;
    ctaLabel?: string;
    external?: boolean;
    as?: React.ElementType;
  }>;

export function Teaser({
  children,
  className,
  ctaLabel,
  external,
  title,
  pictogram,
  as: AsLink = Link,
  ...linkProps
}: TeaserProps) {
  return (
    <AsLink {...linkProps} className={cn(styles.container, className)}>
      {title && (
        <div className={styles['header']}>
          {pictogram && (
            <Pictogram
              name={pictogram}
              size="2rem"
              className={styles['pictogram']}
            />
          )}
          <h3>{title}</h3>
        </div>
      )}
      {children}
      {ctaLabel && (
        <span className={cn(linkStyles['link'], styles['cta'])}>
          {ctaLabel}
          <Icon
            name={external ? 'link-external' : 'arrow-right'}
            className={cn(external ? styles['external'] : styles['internal'])}
          />
        </span>
      )}
    </AsLink>
  );
}
