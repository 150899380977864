import 'reflect-metadata';
//import { FieldValues } from 'react-hook-form';
import { FieldProps, FieldType } from '../types/field';

const defaultOptions = {
  type: 'text',
  fieldType: 'input',
};

/**
 * Property decorator for form fields
 * @param props
 * @returns
 */
export function Field<Type extends FieldType = 'input'>(
  fieldTypeOrOptions: Type | (FieldProps<Type> & { fieldType?: Type }),
  props?: FieldProps<Type>,
): PropertyDecorator {
  return (target: object, propertyKey: string | symbol) => {
    const properties =
      typeof fieldTypeOrOptions === 'string'
        ? { fieldType: fieldTypeOrOptions, ...props }
        : fieldTypeOrOptions;

    // if props.fieldType is defined, use it, otherwise use 'input'
    properties.fieldType = properties.fieldType || ('input' as Type);

    // First we need to register the property as a field on the class
    // This is done by adding a metadata key to the class prototype
    // The metadata key is `fields` and the value is an array of field names
    const fields = Reflect.getMetadata('fields', target) || [];
    Reflect.defineMetadata('fields', [...fields, propertyKey], target);

    // Get the fieldType for the property
    const propertyType = Reflect.getMetadata(
      'design:type',
      target,
      propertyKey,
    );

    if (!propertyType) {
      console.warn(
        `Property type metadata is undefined for ${String(propertyKey)}`,
      );
    }

    // Determine the type (string, number, boolean, etc.)
    //const dataType =
    //  (propertyType?.name.toLowerCase() as FieldValues) || 'string';
    // set some default values
    const defaultProps = {
      ...defaultOptions,
      propertyType: propertyType?.name.toLowerCase() || 'string',
      ...properties,
      name: properties.name || String(propertyKey),
    };
    // Next we need to store the field metadata on the property itself
    Reflect.defineMetadata('field', defaultProps, target, propertyKey);
  };
}
