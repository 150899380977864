import { HttpStatus } from '../enums/http-status.enum';

export type Cause = Error | string | Record<string, unknown>;

export class HttpException extends Response {
  cause?: string;
  constructor(
    message: string,
    status: HttpStatus = HttpStatus.INTERNAL_SERVER_ERROR,
    cause?: Cause,
  ) {
    super(message, { status });
    this.cause = typeof cause === 'string' ? cause : JSON.stringify(cause);
  }
}
