import { SvgSymbol } from '../symbol-factory';

export const Process: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 3.25 9.078 C 4.367 6.406 6.971 4.536 10 4.536 C 11.654 4.536 13.242 5.206 14.413 6.398 L 16.588 8.607 L 14.333 8.607 C 13.779 8.607 13.333 9.061 13.333 9.625 C 13.333 10.189 13.779 10.643 14.333 10.643 L 19 10.643 C 19.554 10.643 20 10.189 20 9.625 L 20 4.875 C 20 4.311 19.554 3.857 19 3.857 C 18.446 3.857 18 4.311 18 4.875 L 18 7.169 L 15.829 4.956 C 14.283 3.382 12.188 2.5 10 2.5 C 6.142 2.5 2.829 4.883 1.408 8.281 C 1.192 8.798 1.429 9.396 1.938 9.617 C 2.446 9.837 3.033 9.595 3.25 9.078 Z M 18.583 15.736 C 18.8 15.219 18.567 14.621 18.058 14.4 C 17.55 14.18 16.963 14.417 16.746 14.935 C 15.625 17.603 13.025 19.464 10 19.464 C 8.346 19.464 6.758 18.794 5.588 17.603 L 3.412 15.393 L 5.667 15.393 C 6.221 15.393 6.667 14.939 6.667 14.375 C 6.667 13.811 6.221 13.357 5.667 13.357 L 1 13.357 C 0.446 13.357 0 13.811 0 14.375 L 0 19.125 C 0 19.689 0.446 20.143 1 20.143 C 1.554 20.143 2 19.689 2 19.125 L 2 16.831 L 4.171 19.04 C 5.717 20.618 7.813 21.5 10 21.5 C 13.854 21.5 17.158 19.125 18.583 15.736 Z" />
  </svg>
);
