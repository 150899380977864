import styles from './back-link.module.css';
import { ClassName, cn } from '@lhb/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { To, useNavigate } from 'react-router-dom';

type BackButtonProp = {
  label: React.ReactNode;
  className?: ClassName;
  requestId?: string;
  to?: To;
};

export function BackLink({ label, className, to }: BackButtonProp) {
  'use client';
  const { t } = useTranslation();
  label = label || t('COMMON:GO_BACK');
  const navigate = useNavigate();
  const goBack = useCallback(() => {
    if (to) {
      navigate(to);
      return;
    }

    if (!window) return;
    window.history.back();
  }, [to, navigate]);

  return (
    <button onClick={goBack} className={cn(styles['link'], className)}>
      {label}
    </button>
  );
}
