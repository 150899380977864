import styles from '../error.module.css';
import { Button, cn } from '@lhb/ui';
import { Link } from '@remix-run/react';

type Error403Props = {
  link?: React.ElementType;
};

/**
 * @TODO: Convert to i18n compatible
 */
export function Error403({ link: AsLink = Link }: Error403Props) {
  return (
    <>
      <h1 className={cn('display', styles['heading'])}>Vi beklagar</h1>
      <p className={cn('intro')}>
        Något verkar ha gått fel, vänligen försök igen. Om problemet kvarstår
        ber vi dig kontakta oss så hjälper vi dig vidare.
      </p>

      <Button asChild icon="return" className={styles.link}>
        <AsLink to="https://landshypotek.se">
          Tillbaka till landshypotek.se
        </AsLink>
      </Button>
    </>
  );
}
