import { SvgSymbol } from '../symbol-factory';

export const Settings: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 8.095 2 L 7.641 4.5 C 7.201 4.639 6.773 4.818 6.369 5.028 L 4.271 3.585 L 1.577 6.278 L 3.028 8.369 C 2.817 8.774 2.64 9.199 2.5 9.641 L 0 10.095 L 0 13.905 L 2.5 14.359 C 2.64 14.802 2.824 15.226 3.036 15.631 L 1.577 17.722 L 4.271 20.415 L 6.362 18.972 C 6.767 19.184 7.198 19.36 7.641 19.5 L 8.095 22 L 11.905 22 L 12.359 19.5 C 12.801 19.361 13.226 19.183 13.631 18.972 L 15.722 20.415 L 18.415 17.722 L 16.964 15.631 C 17.175 15.227 17.354 14.807 17.493 14.366 L 20 13.905 L 20 10.095 L 17.493 9.641 C 17.354 9.201 17.181 8.779 16.972 8.376 L 18.415 6.278 L 15.722 3.585 L 13.631 5.028 C 13.227 4.817 12.801 4.64 12.359 4.5 L 11.905 2 L 8.095 2 Z M 10 7.714 C 12.367 7.714 14.286 9.633 14.286 12 C 14.286 14.367 12.367 16.286 10 16.286 C 7.633 16.286 5.714 14.367 5.714 12 C 5.714 9.633 7.633 7.714 10 7.714 Z" />
  </svg>
);
