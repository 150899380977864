import { ComponentPropsWithRef, PropsWithChildren } from 'react';
import style from './typography.module.css';
import { ClassName, cn } from '../../utils';
import { Slot, Slottable } from '@radix-ui/react-slot';

type TypographyProps = ComponentPropsWithRef<'p'> &
  PropsWithChildren<{
    variant?: 'title' | 'lead' | 'body' | 'body-small' | 'body-bold';
    color?: 'success' | 'danger' | 'warning';
    asChild?: boolean;
    mb?: 'xs' | 's' | 'm' | 'l' | 'xl';
    mt?: 'xs' | 's' | 'm' | 'l' | 'xl';
    my?: 'xs' | 's' | 'm' | 'l' | 'xl';
    className?: ClassName;
  }>;

export function Text({
  asChild,
  variant = 'body',
  color,
  children,
  mb,
  mt,
  my,
  className,
}: TypographyProps) {
  const Comp = asChild ? Slot : 'p';

  return (
    <Comp
      className={cn(
        variant && style[variant],
        color && style[color],
        mb && style[`margin-bottom-${mb}`],
        mt && style[`margin-top-${mt}`],
        my && style[`margin-block-${my}`],
        className,
      )}
    >
      <Slottable>{children}</Slottable>
    </Comp>
  );
}
