import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { doCheck } from '../../../helpers/check';
import { ReturnUrlStore } from '../../../helpers/return-url.store';
import { getSuccessUrl } from '../../../helpers/success-url';
import { CheckData } from '../../../helpers/types';
import { useContext, useEffect, useState } from 'react';
import { catchError, mergeMap, takeWhile, interval, tap } from 'rxjs';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export function useBankIdCheck(requestId: string) {
  const ai = useContext(AppInsightsContext);
  const [state, setState] = useState<CheckData & { error?: string }>({
    state: 'pending',
    status: 'OutstandingTransaction',
  });

  useEffect(() => {
    const subject$ = interval(1000)
      .pipe(
        mergeMap(async () => doCheck(requestId)),
        takeWhile((data) => data && data.state === 'pending', true),
        tap((data) => {
          console.log('BankIdCheck', data);
          ai.trackTrace(
            {
              severityLevel: SeverityLevel.Information,
              message: 'Checking BankId status',
            },
            { state: data.state, status: data.status },
          );
        }),
        catchError((error) => {
          console.error('BankIdCheck', error);
          ai.trackException({
            error,
            severityLevel: SeverityLevel.Error,
            properties: { hook: 'useBankIdCheck' },
          });
          setState({
            state: 'failed',
            status: 'Unknown',
            error: error.message,
          });
          throw error;
        }),
      )
      .subscribe((res: CheckData) => {
        setState(res);
        if (res.state !== 'pending')
          ai.trackEvent({
            name: 'BankIdCheck',
            properties: { state: res.state, status: res.status },
          });
        if (res.state === 'success') {
          const returnUrl = ReturnUrlStore.get();
          if (!returnUrl) throw new Error('no returnUrl found');
          ai.trackTrace({
            severityLevel: SeverityLevel.Information,
            message: 'Redirecting to returnUrl',
          });
          console.log('Redirecting to returnUrl', returnUrl);
          window.location.href = getSuccessUrl(requestId, returnUrl);
        }
      });

    return () => subject$.unsubscribe();
  }, [requestId]);

  return state;
}
