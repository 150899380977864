import { Text } from '@lhb/ui';
import { PropsWithChildren } from 'react';

export function FormMessage({ children }: PropsWithChildren) {
  return (
    <Text variant="body" color="danger">
      {children}
    </Text>
  );
}
