import { SvgSymbol } from '../symbol-factory';

export const CheckmarkCircle: SvgSymbol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18 9.47115C18 8.57234 16.9065 8.12984 16.2814 8.7757L10.5104 14.7386L8.20615 12.2597C7.82039 11.8447 7.16724 11.8328 6.7666 12.2334L6.70271 12.2973C6.31391 12.6861 6.31195 13.3159 6.69832 13.7071L9.79968 16.8474C10.1909 17.2435 10.8303 17.2438 11.2219 16.8481L17.7107 10.2923C17.896 10.105 18 9.85223 18 9.58879V9.47115Z"
    />
  </svg>
);
