import { CSSProperties } from 'react';
import { BackLink } from '../../components/back-link';
import sharedStyles from '../shared.module.css';
import { cn } from '@lhb/ui';
import { useRouteError } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorGrafik } from './assets/error-graphic';
import { MessageBlock } from '../../components/blocks';

export function BankIdError() {
  const { t } = useTranslation('BANKID_ERROR');
  const error = useRouteError() as Error;

  const errorStyles = {
    '--layer-1': 'var(--colors-apricot_orange)',
    '--layer-2': 'var(--colors-tacao_orange)',
  } as CSSProperties;

  return (
    <>
      <h1 className={sharedStyles['heading']}>{t('ERROR_PAGE_LABEL')}</h1>
      <ErrorGrafik
        width="13rem"
        style={errorStyles}
        className={sharedStyles['error-graphics']}
      />
      <MessageBlock>
        <Trans
          i18nKey={error.message}
          ns="BANKID_ERROR"
          defaults="<p>Någonting gick fel, var god försök igen.<br>Om problemet kvarstår, kontakta kundservice.</p>"
          components={{
            p: <p className={cn('text-bold', sharedStyles['subtitle'])} />,
          }}
        />
      </MessageBlock>

      <BackLink label={t('RESTART_BUTTON_LABEL')} to="../" />
    </>
  );
}
