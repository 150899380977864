import { Button } from '@lhb/ui';
import { isMobile } from '@lhb/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export function BankIdButtons() {
  const { t } = useTranslation('BANKID');

  if (isMobile().any)
    return (
      <>
        <Button icon="bankid" block asChild>
          <Link to="/bankid/local" viewTransition>
            {t('MOBILE_DEVICE')}
          </Link>
        </Button>
        <Button asChild variant="tertriary" block>
          <Link to="/bankid/other" viewTransition>
            {t('OTHER_DEVICE')}
          </Link>
        </Button>
      </>
    );

  return (
    <>
      <Button asChild icon="bankid" block>
        <Link to="/bankid/other" viewTransition>
          {t('MOBILE_DEVICE')}
        </Link>
      </Button>
      <Button variant="tertriary" block asChild>
        <Link to="/bankid/local">{t('SAME_DEVICE')}</Link>
      </Button>
    </>
  );
}
