import { Trans, useTranslation } from 'react-i18next';
import { BankIdButtons } from '../components/auth-buttons';
import sharedStyles from './shared.module.css';
import { Button, Dialog, cn } from '@lhb/ui';
import { createRef, useContext } from 'react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

const dialogRef = createRef<HTMLDialogElement>();

export function HomeRoute() {
  const ai = useContext(AppInsightsContext);
  const { t } = useTranslation('START_SCREEN');
  const getHelp = () => {
    'use client';
    ai.trackEvent({ name: 'Show Help Dialog' });
    dialogRef.current?.showModal();
  };

  return (
    <>
      <div>
        <h1 className={sharedStyles['heading']}>{t('TITLE')}</h1>
        <p className={cn('text-bold', sharedStyles['subtitle'])}>
          {t('SUBTITLE')}
        </p>
      </div>

      <div className={sharedStyles['button-group']}>
        <BankIdButtons />
      </div>
      <Button onClick={getHelp} icon="info-circle" variant="tertriary" block>
        {t('HELP_BUTTON_LABEL')}
      </Button>

      <Dialog
        id="idp-help"
        title="Jag vill ha hjälp"
        closeAria={t('CLOSE', { ns: 'COMMON' })}
        ref={dialogRef}
        bottom
        dismissible
      >
        <Trans
          i18nKey="HELP_DIALOG_TEXT"
          ns="START_SCREEN"
          components={{
            dl: <dl />,
            dt: <dt />,
            dd: <dd />,
            a: (
              <a
                href="https://bankid.com"
                target="_blank"
                referrerPolicy="no-referrer"
              />
            ),
          }}
        />
      </Dialog>
    </>
  );
}
