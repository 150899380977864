import { SvgSymbol } from '../symbol-factory';

export const ArrowLeft: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 17 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 5.293 17.707 L 0.293 12.707 C -0.098 12.317 -0.098 11.684 0.293 11.293 L 5.293 6.293 C 5.683 5.902 6.317 5.902 6.707 6.293 C 7.098 6.684 7.098 7.317 6.707 7.707 L 3.414 11.001 L 16 11 C 16.552 11 17 11.448 17 12 C 17 12.552 16.552 13 16 13 L 3.416 13.001 L 6.707 16.293 C 7.098 16.684 7.098 17.317 6.707 17.707 C 6.317 18.098 5.683 18.098 5.293 17.707 Z" />
  </svg>
);
