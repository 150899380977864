import { SvgSymbol } from '../symbol-factory';

export const ArrowRight: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 17 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 11.707 6.293 L 16.707 11.293 C 17.098 11.683 17.098 12.316 16.707 12.707 L 11.707 17.707 C 11.317 18.098 10.683 18.098 10.293 17.707 C 9.902 17.316 9.902 16.683 10.293 16.293 L 13.586 12.999 L 1 13 C 0.448 13 0 12.552 0 12 C 0 11.448 0.448 11 1 11 L 13.584 10.999 L 10.293 7.707 C 9.902 7.316 9.902 6.683 10.293 6.293 C 10.683 5.902 11.317 5.902 11.707 6.293 Z" />
  </svg>
);
