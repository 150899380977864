import { DataFromFieldName } from '../types/form';
import { FormSchema } from '../types/form';

/**
 * Takes a schema and returns an array of fields as objects
 * @param schema - Schema to get fields from
 * @returns An array of fields from the schema
 */
export function getFields<Schema extends FormSchema<object>>(
  schema: Schema,
): (DataFromFieldName<Schema, keyof Schema> & {
  name: keyof Schema;
})[] {
  const fields = Reflect.getMetadata('fields', schema.prototype);
  return fields.map((name: keyof Schema) => {
    const fieldData = Reflect.getMetadata(
      'field',
      schema.prototype,
      name as string,
    );
    return { name, ...fieldData };
  });
}
