import React, {
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  forwardRef,
} from 'react';
import { cn } from '../../../../utils';
import styles from './input.module.css';

export type ExcludedTypes = 'button' | 'file' | 'image' | 'reset' | 'submit';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  invert?: boolean;
  error?: boolean;
  align?: 'start' | 'end';
  prefix?: React.ReactNode | React.ReactElement;
  suffix?: React.ReactNode | React.ReactElement;
  type?: Omit<HTMLInputTypeAttribute, ExcludedTypes>;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { className, invert, error, align, prefix, suffix, hidden, ...props },
    ref,
  ) => {
    align = align || (prefix && 'start') || (suffix && 'end') || undefined;

    return (
      <div
        className={cn(
          styles['input-wrapper'],
          !!prefix && styles['prefixed'],
          !!suffix && styles['suffixed'],
          invert && styles['invert'],
          error && styles['error'],
          hidden && styles['hidden'],
          className,
        )}
      >
        {prefix && <span className={styles.prefix}>{prefix}</span>}
        <input
          {...props}
          ref={ref}
          hidden={hidden}
          className={cn(styles['element'], align && styles[`align-${align}`])}
        />
        {suffix && <span className={styles.suffix}>{suffix}</span>}
      </div>
    );
  },
);

Input.displayName = 'Input';

export { Input };
