import { SvgSymbol } from '../symbol-factory';

export const Energy: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 58 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 12.046 19.771 L 12.046 20.267 C 12.046 21.001 12.645 21.596 13.385 21.596 C 14.125 21.596 14.723 21.001 14.723 20.267 L 14.723 19.771 C 16.731 19.512 18.293 17.822 18.293 15.759 C 18.293 14.21 17.425 12.817 16.029 12.124 L 11.938 10.095 C 11.455 9.855 11.154 9.372 11.154 8.835 C 11.154 8.059 11.79 7.427 12.573 7.427 L 14.197 7.427 C 14.979 7.427 15.616 8.059 15.616 8.835 L 15.616 9.641 C 15.616 10.374 16.214 10.97 16.954 10.97 C 17.694 10.97 18.293 10.374 18.293 9.641 L 18.293 8.835 C 18.293 6.773 16.731 5.084 14.723 4.823 L 14.723 4.328 C 14.723 3.594 14.125 3 13.385 3 C 12.645 3 12.046 3.594 12.046 4.328 L 12.046 4.823 C 10.039 5.084 8.477 6.773 8.477 8.835 C 8.477 10.384 9.345 11.778 10.741 12.47 L 14.831 14.5 C 15.315 14.74 15.616 15.223 15.616 15.759 C 15.616 16.536 14.979 17.168 14.197 17.168 L 12.573 17.168 C 11.79 17.168 11.154 16.536 11.154 15.759 L 11.154 14.954 C 11.154 14.22 10.555 13.626 9.816 13.626 C 9.076 13.626 8.477 14.22 8.477 14.954 L 8.477 15.759 C 8.477 17.822 10.039 19.512 12.046 19.771 Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 47.738 42.9 L 47.738 42.404 C 47.738 41.671 47.14 41.076 46.4 41.076 C 45.66 41.076 45.062 41.671 45.062 42.404 L 45.062 42.9 C 43.054 43.159 41.492 44.85 41.492 46.912 C 41.492 48.461 42.36 49.855 43.756 50.547 L 47.846 52.577 C 48.33 52.817 48.631 53.299 48.631 53.836 C 48.631 54.612 47.995 55.244 47.212 55.244 L 45.588 55.244 C 44.805 55.244 44.169 54.612 44.169 53.836 L 44.169 53.03 C 44.169 52.297 43.571 51.702 42.831 51.702 C 42.091 51.702 41.492 52.297 41.492 53.03 L 41.492 53.836 C 41.492 55.898 43.054 57.588 45.062 57.848 L 45.062 58.343 C 45.062 59.077 45.66 59.671 46.4 59.671 C 47.14 59.671 47.738 59.077 47.738 58.343 L 47.738 57.848 C 49.746 57.588 51.308 55.898 51.308 53.836 C 51.308 52.287 50.44 50.893 49.044 50.201 L 44.954 48.171 C 44.47 47.931 44.169 47.449 44.169 46.912 C 44.169 46.135 44.805 45.503 45.588 45.503 L 47.212 45.503 C 47.995 45.503 48.631 46.135 48.631 46.912 L 48.631 47.717 C 48.631 48.451 49.23 49.045 49.969 49.045 C 50.709 49.045 51.308 48.451 51.308 47.717 L 51.308 46.912 C 51.308 44.85 49.746 43.159 47.738 42.9 Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 37.815 29.873 L 28.796 43.736 L 30.076 36.298 C 30.148 35.88 30.014 35.453 29.716 35.149 C 29.463 34.89 29.116 34.747 28.757 34.747 C 28.695 34.747 28.631 34.751 28.569 34.76 L 19.897 35.983 L 28.919 20.896 L 27.609 29.657 C 27.547 30.073 27.687 30.495 27.988 30.792 C 28.288 31.089 28.711 31.228 29.132 31.165 L 37.815 29.873 Z M 31.69 13.157 C 31.088 12.945 30.417 13.186 30.091 13.731 L 16.169 37.011 C 15.906 37.45 15.921 38 16.207 38.426 C 16.492 38.851 17.001 39.077 17.509 39.003 L 27.129 37.646 L 25.059 49.681 C 24.951 50.302 25.299 50.913 25.892 51.143 C 26.051 51.203 26.215 51.233 26.377 51.233 C 26.821 51.233 27.249 51.014 27.502 50.625 L 41.671 28.843 C 41.956 28.405 41.956 27.842 41.671 27.402 C 41.385 26.964 40.866 26.733 40.347 26.81 L 30.523 28.272 L 32.566 14.603 C 32.66 13.975 32.293 13.37 31.69 13.157 Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 55.892 21.429 C 55.614 20.749 54.834 20.423 54.148 20.696 C 53.464 20.972 53.133 21.746 53.41 22.427 C 54.68 25.538 55.323 28.834 55.323 32.222 C 55.323 36.202 54.444 40.023 52.712 43.578 C 52.391 44.238 52.669 45.033 53.335 45.352 C 53.522 45.442 53.721 45.485 53.917 45.485 C 54.414 45.485 54.891 45.209 55.122 44.734 C 57.032 40.816 58 36.606 58 32.222 C 58 28.49 57.291 24.86 55.892 21.429 Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 52.775 18.773 C 52.824 18.757 52.867 18.732 52.912 18.711 C 52.968 18.686 53.026 18.67 53.079 18.637 C 53.117 18.614 53.146 18.584 53.18 18.558 C 53.198 18.545 53.216 18.532 53.233 18.516 C 53.34 18.426 53.428 18.321 53.499 18.207 C 53.507 18.194 53.514 18.181 53.521 18.167 C 53.595 18.037 53.648 17.898 53.674 17.753 C 53.674 17.751 53.676 17.749 53.676 17.748 L 54.914 10.771 C 55.043 10.048 54.557 9.36 53.829 9.233 C 53.101 9.107 52.406 9.588 52.278 10.31 L 51.59 14.186 C 46.109 7.41 37.885 3.442 29 3.442 C 26.242 3.442 23.513 3.827 20.888 4.584 C 20.178 4.789 19.77 5.526 19.977 6.23 C 20.183 6.935 20.923 7.338 21.635 7.135 C 24.017 6.448 26.495 6.099 29 6.099 C 36.974 6.099 44.367 9.617 49.341 15.642 L 45.561 14.981 C 44.83 14.856 44.138 15.336 44.01 16.059 C 43.881 16.781 44.368 17.47 45.096 17.597 L 52.126 18.825 C 52.205 18.84 52.283 18.847 52.36 18.847 C 52.377 18.847 52.391 18.839 52.408 18.838 C 52.531 18.833 52.654 18.812 52.775 18.773 Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 5.128 43.244 C 3.502 39.782 2.677 36.072 2.677 32.221 C 2.677 27.489 3.965 22.853 6.402 18.816 C 6.782 18.187 6.576 17.371 5.943 16.994 C 5.307 16.618 4.486 16.821 4.106 17.451 C 1.42 21.9 0 27.008 0 32.221 C 0 36.464 0.909 40.549 2.702 44.367 C 2.929 44.849 3.412 45.134 3.916 45.134 C 4.105 45.134 4.297 45.094 4.48 45.009 C 5.15 44.7 5.44 43.909 5.128 43.244 Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 38.94 56.418 C 35.784 57.695 32.44 58.344 29 58.344 C 21.717 58.344 14.959 55.452 10.015 50.317 L 13.794 50.716 C 14.526 50.798 15.188 50.265 15.267 49.535 C 15.344 48.806 14.812 48.151 14.076 48.074 L 6.978 47.326 C 6.943 47.323 6.91 47.326 6.876 47.325 C 6.83 47.324 6.785 47.322 6.74 47.324 C 6.7 47.328 6.66 47.336 6.621 47.342 C 6.573 47.35 6.525 47.357 6.478 47.37 C 6.445 47.379 6.414 47.393 6.382 47.404 C 6.331 47.423 6.28 47.441 6.231 47.466 C 6.205 47.48 6.18 47.497 6.155 47.512 C 6.114 47.536 6.072 47.555 6.034 47.584 C 6.024 47.592 6.018 47.601 6.008 47.608 C 5.99 47.622 5.975 47.639 5.958 47.654 C 5.907 47.697 5.859 47.741 5.817 47.789 C 5.81 47.798 5.806 47.807 5.799 47.815 C 5.75 47.874 5.706 47.934 5.669 47.999 C 5.662 48.013 5.657 48.028 5.65 48.042 C 5.617 48.104 5.588 48.167 5.567 48.233 C 5.558 48.257 5.555 48.282 5.549 48.306 C 5.533 48.367 5.517 48.427 5.509 48.488 C 5.509 48.495 5.507 48.5 5.506 48.506 L 4.752 55.552 C 4.674 56.281 5.207 56.935 5.942 57.013 C 5.989 57.018 6.037 57.02 6.085 57.02 C 6.76 57.02 7.34 56.513 7.414 55.831 L 7.835 51.895 C 13.303 57.715 20.852 61 29 61 C 32.788 61 36.473 60.286 39.952 58.877 C 40.637 58.599 40.965 57.825 40.686 57.145 C 40.405 56.465 39.625 56.141 38.94 56.418 Z"
    />
  </svg>
);
