import {
  isString,
  registerDecorator,
  ValidationOptions,
} from 'class-validator';

/**
 * Validates a Swedish mobile number
 * @reference https://sv.wikipedia.org/wiki/Lista_%C3%B6ver_svenska_riktnummer#Prefixnummer
 * @param value string - mobile number
 * @returns boolean
 * @source https://sv.wikipedia.org/wiki/Riktnummer
 */
export const mobileRegex = /^07[02369]-?\d{7}$/;
export function isMobile(value: string) {
  return mobileRegex.test(value);
}

/**
 * Validates that the value is a valid Swedish mobile number
 * @param validationOptions - class-validator ValidationOptions
 * @returns
 * @source https://sv.wikipedia.org/wiki/Riktnummer
 */
export function IsMobile(validationOptions?: ValidationOptions) {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isMobile',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: unknown) {
          return isString(value) && isMobile(value);
        },
      },
    });
  };
}
