import { cn } from '../../utils';
import { Icon, Icons } from '../symbols';
import styles from './alert.module.css';
import { MouseEventHandler, PropsWithChildren, TouchEventHandler } from 'react';

export enum AlertType {
  Info = 'info',
  User = 'user',
  Success = 'success',
  Error = 'error',
}

export type AlertProps = PropsWithChildren<{
  id: string;
  title?: React.ReactNode;
  type?: AlertType;
  icon?: Icons | string;
  dismissable?: boolean;
  closeAria?: string;
  closeText?: string;
  onClose?: (id: string) => void;
}>;

export function Alert({
  id,
  children,
  title,
  type,
  icon,
  closeAria = 'Stäng meddelandet',
  closeText = 'Stäng',
  ...rest
}: AlertProps) {
  // @ts-expect-error: Deprecated fallback for type
  type = type === 'system' ? AlertType.Info : type;
  type = type ? type : AlertType.Info;
  icon = icon ? icon : defaultIconByType(type);
  const { dismissable, onClose } = rest;

  const closeAlert: MouseEventHandler<HTMLButtonElement> = () => {
    console.log('closing alert', id);
    if (typeof onClose === 'function') onClose(id);
  };

  return (
    <div className={cn(styles['alert'], styles[type])}>
      <div className={styles['header']}>
        <h3 className={styles['title']}>
          <Icon name={icon as unknown as Icons} size="1.25rem" /> {title}
        </h3>
        {dismissable && (
          <button
            className={styles['close-button']}
            title={closeAria}
            onClick={closeAlert}
            onTouchEnd={
              closeAlert as unknown as TouchEventHandler<HTMLButtonElement>
            }
            type="button"
          >
            {closeText} <Icon name="cross-small" />
          </button>
        )}
      </div>
      {children}
    </div>
  );
}

function defaultIconByType(type: string): Icons {
  switch (type) {
    case 'success':
      return 'checkmark-circle';
    case 'error':
      return 'error';
    default:
      return 'info-circle';
  }
}
