import { SvgSymbol } from '../symbol-factory';

export const Menu: SvgSymbol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M23 17C23.5523 17 24 17.4477 24 18C24 18.5523 23.5523 19 23 19H1C0.447715 19 0 18.5523 0 18C0 17.4477 0.447715 17 1 17H23ZM23 11C23.5523 11 24 11.4477 24 12C24 12.5523 23.5523 13 23 13H1C0.447715 13 0 12.5523 0 12C0 11.4477 0.447715 11 1 11H23ZM23 5C23.5523 5 24 5.44772 24 6C24 6.55228 23.5523 7 23 7H1C0.447715 7 0 6.55228 0 6C0 5.44772 0.447715 5 1 5H23Z" />
  </svg>
);
