import { SvgSymbol } from '../symbol-factory';

export const MinusCircle: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 24 12 A 12 12 0 0 1 12 24 A 12 12 0 0 1 0 12 A 12 12 0 0 1 12 0 A 12 12 0 0 1 24 12 Z M6 12C6 11.4477 6.44772 11 7 11H17C17.5523 11 18 11.4477 18 12V12C18 12.5523 17.5523 13 17 13H7C6.44772 13 6 12.5523 6 12V12Z"
    />
  </svg>
);
