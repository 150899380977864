import { ErrorPage } from '@lhb/pages';
import { HttpStatus } from '@lhb/utils';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useContext, useEffect } from 'react';
import {
  isRouteErrorResponse,
  Link,
  useRouteError,
  useSearchParams,
} from 'react-router-dom';

export function ErrorRoute() {
  const [params] = useSearchParams();
  const error = useRouteError();
  const ai = useContext(AppInsightsContext);

  let errorCode = params.get('errorCode');
  let statusCode: HttpStatus =
    (params.get('statusCode') as HttpStatus | null) ??
    HttpStatus.INTERNAL_SERVER_ERROR;

  if (isRouteErrorResponse(error)) {
    errorCode = error.data.message ?? error.data;
    statusCode = error.status;
  }

  console.log('ErrorRoute', { errorCode, statusCode });

  useEffect(() => {
    ai.trackEvent({
      name: 'ErrorRoute',
      properties: { errorCode, statusCode },
    });
  }, [ai, errorCode, statusCode]);

  return <ErrorPage link={Link} status={statusCode} />;
}
