import { Alert, AlertProps } from '../alert';
import { Logo } from './logo';
import styles from './site-header.module.css';
import { PropsWithChildren } from 'react';

export type HeaderProps = PropsWithChildren<{
  dismiss?: (id: string) => void;
  alerts?: (Omit<AlertProps, 'children'> & {
    id: string;
    text?: React.ReactNode;
  })[];
  texts?: {
    alertCloseText: string;
    alertCloseAria: string;
  };
}>;

export function SiteHeader({ alerts, texts, dismiss, children }: HeaderProps) {
  return (
    <div className={styles['wrapper']}>
      <header className={styles['site-header']}>
        <div>
          <div className={styles['logo']}>
            <Logo />
          </div>
          {children}
        </div>
      </header>
      {alerts?.map(({ text, ...alert }) => (
        <Alert
          {...alert}
          key={alert.id}
          children={text}
          onClose={dismiss}
          closeAria={texts?.alertCloseAria}
          closeText={texts?.alertCloseText}
        />
      ))}
    </div>
  );
}
