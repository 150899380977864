import styles from './button.module.css';
import { ClassName, cn } from '../../utils';
import {
  AriaRole,
  ComponentPropsWithRef,
  PropsWithChildren,
  TouchEventHandler,
  forwardRef,
} from 'react';
import { Icon, Icons } from '../symbols';
import { Slot, Slottable } from '@radix-ui/react-slot';

export type ButtonProps = ComponentPropsWithRef<'button'> &
  PropsWithChildren<{
    asChild?: boolean;
    variant?:
      | 'primary'
      | 'secondary'
      | 'tertriary'
      | 'link'
      | 'info'
      | 'success'
      | 'danger';
    size?: 'default' | 'small';
    block?: boolean | 'mobile';
    className?: ClassName;
    icon?: Icons;
    leadingIcon?: Icons;
    trailingIcon?: Icons;
    role?: AriaRole;
  }>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild,
      className,
      block,
      variant = 'primary',
      size,
      icon,
      leadingIcon,
      trailingIcon,
      children,
      type = 'button',
      ...props
    },
    ref,
  ) => {
    leadingIcon = leadingIcon || icon;
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          styles['button'],
          variant && styles[variant],
          block && styles['block'],
          block === 'mobile' && styles['block-mobile'],
          size && styles[size],
          className,
        )}
        type={type}
        ref={ref}
        onTouchEnd={
          props.onClick
            ? (props.onClick as unknown as TouchEventHandler<HTMLButtonElement>)
            : undefined
        }
        {...props}
      >
        {leadingIcon && <Icon name={leadingIcon} />}
        <Slottable>{children}</Slottable>
        {trailingIcon && <Icon name={trailingIcon} />}
      </Comp>
    );
  },
);

export { Button };
