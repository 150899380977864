import { SvgSymbol } from '../symbol-factory';

export const Return: SvgSymbol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1886 8.63187H7.98325L9.12065 7.49446C9.46249 7.15263 9.46249 6.59822 9.12065 6.25638C8.77881 5.91454 8.22502 5.91454 7.88318 6.25638L5.25592 8.88794C5.13472 9.00914 5.05206 9.16266 5.01725 9.33046C4.99425 9.44669 4.99425 9.56727 5.01725 9.6835C5.05205 9.85132 5.13472 10.0048 5.25592 10.126L7.88127 12.7514C8.22311 13.0932 8.77752 13.0932 9.11936 12.7514C9.4612 12.4095 9.4612 11.8551 9.11936 11.5133L7.98134 10.3821H14.1867C15.2812 10.3821 16.2924 10.9657 16.8395 11.9136C17.387 12.8614 17.387 14.0286 16.8395 14.9765C16.2925 15.9243 15.2813 16.5079 14.1867 16.5079H5.8731C5.39452 16.5079 5.00606 16.8964 5.00606 17.375C5.00606 17.8542 5.39452 18.242 5.8731 18.242H14.1867C15.9066 18.242 17.4958 17.3246 18.3553 15.8354C19.2149 14.3462 19.2149 12.5114 18.3553 11.0223C17.4957 9.53308 15.9064 8.6157 14.1867 8.6157L14.1886 8.63187Z"
    />
  </svg>
);
