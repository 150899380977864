import { SvgSymbol } from '../symbol-factory';

export const Phone: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 6.667 6.473 L 4.108 3.914 L 3.162 4.861 C 1.972 6.05 1.663 7.861 2.389 9.378 C 3.55 11.802 5.023 13.905 6.809 15.691 C 8.595 17.477 10.698 18.95 13.122 20.111 C 14.64 20.837 16.45 20.528 17.639 19.338 L 18.586 18.392 L 16.027 15.833 L 15.199 16.661 C 14.643 17.217 13.813 17.394 13.079 17.114 C 11.233 16.41 9.662 15.414 8.374 14.126 C 7.086 12.838 6.09 11.267 5.386 9.421 C 5.106 8.687 5.283 7.857 5.839 7.301 L 6.667 6.473 Z M 15.32 13.712 C 15.711 13.321 16.344 13.321 16.734 13.712 L 20.707 17.685 C 21.098 18.075 21.098 18.708 20.707 19.099 L 19.054 20.752 C 17.264 22.542 14.541 23.008 12.259 21.915 C 9.633 20.658 7.343 19.054 5.394 17.106 C 3.446 15.157 1.842 12.867 0.585 10.241 C -0.508 7.959 -0.042 5.236 1.748 3.446 L 3.401 1.793 C 3.792 1.402 4.425 1.402 4.815 1.793 L 8.788 5.766 C 9.179 6.156 9.179 6.79 8.788 7.18 L 7.256 8.712 C 7.862 10.299 8.705 11.629 9.788 12.712 C 10.871 13.795 12.201 14.638 13.788 15.244 L 15.32 13.712 Z" />
  </svg>
);
