import { Icon, Link } from '@lhb/ui';
import { Link as RRLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

export function FraudWarning() {
  const { t } = useTranslation('FRAUD_INFO');
  return (
    <>
      <h3>
        <Icon name="info-circle" /> {t('FRAUD_LABEL')}
      </h3>
      <Trans
        i18nKey="FRAUD_TEXT"
        ns="FRAUD_INFO"
        components={{
          p: <p />,
          ul: <ul />,
          li: <li />,
          fraudInfoLink: (
            <Link
              as={RRLink}
              external
              to="https://www.landshypotek.se/kundservice/kund-hos-oss/svarlurad/"
              target="_blank"
            />
          ),
        }}
      />
    </>
  );
}
