import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
/* var clickPluginInstance = new ClickAnalyticsPlugin();
   var clickPluginConfig = {
     autoCapture: true
}; */
const appInsights = (() => {
  if (!window.config?.aiKey && !import.meta.env.VITE_AI_KEY) return;

  const ai = new ApplicationInsights({
    config: {
      enableAutoRouteTracking: true,
      connectionString: window.config?.aiKey || import.meta.env.VITE_AI_KEY,
      // *** If you're adding the Click Analytics plug-in, delete the next line. ***
      extensions: [reactPlugin],
      disableAjaxTracking: false,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      // *** Add the Click Analytics plug-in. ***
      // extensions: [reactPlugin, clickPluginInstance],
    },
  });
  ai.loadAppInsights();

  ai.addTelemetryInitializer((env: ITelemetryItem) => {
    env.tags = env.tags || [];
    env.tags['ai.cloud.role'] = 'lhb.frontend.idp';
  });
  return ai;
})();

export { appInsights, reactPlugin };
