import { SvgSymbol } from '../symbol-factory';

export const Cross: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 14 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 1.707 5.293 L 7 10.585 L 12.293 5.293 C 12.653 4.932 13.221 4.904 13.613 5.209 L 13.707 5.293 C 14.098 5.684 14.098 6.317 13.707 6.707 L 8.415 12 L 13.707 17.293 C 14.098 17.684 14.098 18.317 13.707 18.707 C 13.317 19.098 12.683 19.098 12.293 18.707 L 7 13.415 L 1.707 18.707 C 1.347 19.068 0.779 19.096 0.387 18.791 L 0.293 18.707 C -0.098 18.317 -0.098 17.684 0.293 17.293 L 5.585 12 L 0.293 6.707 C -0.098 6.317 -0.098 5.684 0.293 5.293 C 0.683 4.902 1.317 4.902 1.707 5.293 Z" />
  </svg>
);
