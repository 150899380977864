export enum Case {
  Camel = 'camel',
  Pascal = 'pascal',
  Snake = 'snake',
  Kebab = 'kebab',
  Title = 'title',
  Sentence = 'sentence',
}
/**
 * Converts a string between different casings
 * @param str - the string to convert
 * @param toCase - the case you want to convert it to. Defaults to `camel`
 * @returns the converted string in the desired format
 */
export function convertCase(str: string, toCase: Case = Case.Camel) {
  if (!str) return '';

  const delimiter =
    toCase === Case.Snake
      ? '_'
      : toCase === Case.Kebab
        ? '-'
        : [Case.Title, Case.Sentence].includes(toCase)
          ? ' '
          : '';

  const transform = [Case.Camel, Case.Pascal].includes(toCase)
    ? (x: string) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase()
    : [Case.Snake, Case.Kebab].includes(toCase)
      ? (x: string) => x.toLowerCase()
      : toCase === Case.Title
        ? (x: string) => x.slice(0, 1).toUpperCase() + x.slice(1)
        : (x: string) => x;

  const finalTransform =
    toCase === Case.Camel
      ? (x: string) => x.slice(0, 1).toLowerCase() + x.slice(1)
      : toCase === Case.Sentence
        ? (x: string) => x.slice(0, 1).toUpperCase() + x.slice(1)
        : (x: string) => x;

  const words = str.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
  );

  return (words && finalTransform(words.map(transform).join(delimiter))) || str;
}
