import { PropsWithChildren } from 'react';
import { SiteFooter, SafariFlexGridFix } from '../../components';

type SiteLayoutProps = PropsWithChildren<{
  footer?: React.ReactNode;
}>;

export function SiteLayout({ children, footer }: SiteLayoutProps) {
  return (
    <>
      <SafariFlexGridFix parentId="root" />
      {children}
      <SiteFooter>{footer}</SiteFooter>
    </>
  );
}
