import { Footer } from './footer';
import { AlertsService } from '@lhb/api/idp';
import { AlertProps, SiteHeader, SiteLayout, useSeason } from '@lhb/ui';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { Outlet, json, useLoaderData, useLocation } from 'react-router-dom';
import { appInsights } from '../appinsight';
import { useTranslation } from 'react-i18next';

export async function PageLoader() {
  try {
    const alerts = await AlertsService.getAlerts();
    return json(alerts, { status: 200 });
  } catch (e) {
    appInsights?.trackException({
      error: e as Error,
      properties: { loader: 'Alerts' },
    });
    return json([], { status: 200 });
  }
}

export function PageLayout() {
  const { t } = useTranslation();
  const ai = useContext(AppInsightsContext);
  const alertsData = useLoaderData() as (Omit<AlertProps, 'children'> & {
    id: string;
    text?: ReactNode;
  })[];
  const [alerts, setAlerts] = useState(alertsData);
  const { style: seasonStyle } = useSeason();

  const location = useLocation();

  useEffect(() => {
    console.log('location', location);
  }, [location]);

  useLayoutEffect(() => {
    document.body.classList.add(seasonStyle);
  }, [seasonStyle]);

  const dismiss = async (id: string) => {
    ai.trackEvent({ name: 'AlertDismissed', properties: { id } });
    setAlerts((prev) => prev.filter((alert) => alert.id !== id));
  };

  return (
    <>
      <SiteHeader
        alerts={alerts}
        dismiss={dismiss}
        texts={{
          alertCloseText: t('COMMON:CLOSE'),
          alertCloseAria: t('COMMON:ALERT_CLOSE_ARIA'),
        }}
      />
      <SiteLayout footer={<Footer />}>
        <Outlet />
      </SiteLayout>
    </>
  );
}
