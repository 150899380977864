import styles from './footer-section.module.css';

import { PropsWithChildren } from 'react';
import { Link, LinkProps } from '@remix-run/react';
import { Icon, Icons } from '../../../symbols';
import { cn } from '../../../../utils';

type FooterSectionProps = PropsWithChildren<{
  icon?: Icons;
  title?: React.ReactNode;
}>;

export function FooterSection({ children, icon, title }: FooterSectionProps) {
  return (
    <div className={styles['container']}>
      {(!!icon || !!title) && (
        <h2 className={styles['title']}>
          {icon && <Icon name={icon} />} {title}
        </h2>
      )}
      {children}
    </div>
  );
}

export function FooterMenu({
  className,
  ...props
}: React.HtmlHTMLAttributes<HTMLUListElement>) {
  return <ul className={cn(styles['menu'], className)} {...props} />;
}

type FooterMenuItemProps = Omit<
  LinkProps & React.RefAttributes<HTMLAnchorElement>,
  'children'
> & {
  label: React.ReactNode;
  as?: React.ElementType;
};

export function FooterMenuItem({
  label,
  as: AsLink = Link,
  ...linkProps
}: FooterMenuItemProps) {
  return (
    <li className={styles['menu-item']}>
      <AsLink {...linkProps}>
        {label}
        <Icon name="chevron-right" />
      </AsLink>
    </li>
  );
}
