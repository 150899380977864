import { Link as RRLink } from '@remix-run/react';
import type { RelativeRoutingType, To } from 'react-router-dom';
import { Icon } from '../symbols';
import styles from './link.module.css';
import { cn } from '../../utils';
import React from 'react';

export interface LinkProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  reloadDocument?: boolean;
  replace?: boolean;
  state?: Record<string, unknown>;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
  to: To;
  unstable_viewTransition?: boolean;
  external?: boolean;
  as?: React.ElementType;
}

export function Link({
  external,
  children,
  className,
  as: AsLink = RRLink,
  ...props
}: LinkProps) {
  return (
    <AsLink {...props} className={cn(styles['link'], className)}>
      {children}
      <Icon
        name={external ? 'link-external' : 'arrow-right'}
        className={cn(external ? styles['rotate'] : styles['slide'])}
      />
    </AsLink>
  );
}
