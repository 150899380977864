import { Button as UIButton, ButtonProps as UIButtonProps } from '@lhb/ui';
import { useTranslation } from 'react-i18next';

type ButtonProps = Pick<
  UIButtonProps,
  'type' | 'variant' | 'icon' | 'trailingIcon' | 'leadingIcon'
> & { label?: React.ReactNode };

export function Button({
  type = 'submit',
  variant,
  label,
  ...props
}: ButtonProps) {
  const { t } = useTranslation();

  label = label
    ? label
    : type === 'submit'
      ? t('OK')
      : type === 'reset'
        ? t('CANCEL')
        : t('OK');

  return (
    <UIButton
      type={type}
      variant={
        variant || type === 'submit'
          ? 'primary'
          : type === 'reset'
            ? 'danger'
            : 'secondary'
      }
      {...props}
    >
      {label}
    </UIButton>
  );
}
