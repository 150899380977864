import { SvgSymbol } from '../symbol-factory';

export const Email: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 58 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 55.194 32 C 55.194 46.466 43.466 58.194 29 58.194 C 14.534 58.194 2.806 46.466 2.806 32 C 2.806 17.534 14.534 5.806 29 5.806 C 43.466 5.806 55.194 17.534 55.194 32 Z M 58 32 C 58 48.016 45.016 61 29 61 C 12.984 61 0 48.016 0 32 C 0 15.984 12.984 3 29 3 C 45.016 3 58 15.984 58 32 Z M 45.839 23.113 C 45.839 20.788 43.954 18.903 41.629 18.903 L 16.371 18.903 C 14.046 18.903 12.161 20.788 12.161 23.113 L 12.161 39.952 C 12.161 42.277 14.046 44.161 16.371 44.161 L 41.629 44.161 C 43.954 44.161 45.839 42.277 45.839 39.952 L 45.839 23.113 Z M 29.992 36.734 L 32.728 33.998 L 40.932 41.355 L 17.067 41.355 L 25.27 33.998 L 28.008 36.734 C 28.556 37.282 29.444 37.282 29.992 36.734 Z M 14.968 39.47 L 14.968 23.695 L 23.285 32.012 L 14.968 39.47 Z M 34.714 32.012 L 43.032 23.694 L 43.032 39.47 L 34.714 32.012 Z M 16.953 21.71 L 41.045 21.71 L 29 33.758 L 16.953 21.71 Z"
    />
  </svg>
);
