import { createForm } from '@lhb/form';
import { createFormAction } from '@lhb/form/server';
import {
  Form as FrameworkForm,
  useActionData,
  useSubmit,
  useNavigation,
  redirect,
} from 'react-router-dom';

export const Form = createForm({
  component: FrameworkForm,
  useSubmit,
  useNavigation,
  useActionData,
  i18n: {
    defaultNs: 'COMMON',
    globalError: 'FORM_GLOBAL_ERROR',
  },
});

export const formAction = createFormAction({ redirect });
