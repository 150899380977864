import { SvgSymbol } from '../symbol-factory';

export const ChevronLeft: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 6 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 5.707 15.293 C 6.098 15.684 6.098 16.317 5.707 16.707 C 5.317 17.098 4.683 17.098 4.293 16.707 L 0.293 12.707 C -0.098 12.317 -0.098 11.684 0.293 11.293 L 4.293 7.293 C 4.683 6.902 5.317 6.902 5.707 7.293 C 6.098 7.684 6.098 8.317 5.707 8.707 L 2.414 12 L 5.707 15.293 Z" />
  </svg>
);
