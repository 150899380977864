import { SvgSymbol } from '../symbol-factory';

export const Checkmark: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 15 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 13.19 7.232 C 13.561 6.862 14.162 6.862 14.533 7.232 C 14.902 7.601 14.904 8.198 14.538 8.57 L 6.492 16.747 C 6.103 17.143 5.465 17.146 5.072 16.753 L 0.482 12.162 C 0.091 11.772 0.091 11.139 0.482 10.748 C 0.872 10.357 1.506 10.357 1.896 10.748 L 5.785 14.637 L 13.19 7.232 Z" />
  </svg>
);
