import { SvgSymbol } from '../symbol-factory';

export const ChevronRight: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 6 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 0.293 8.707 C -0.097 8.316 -0.097 7.683 0.293 7.293 C 0.684 6.902 1.317 6.902 1.707 7.293 L 5.707 11.293 C 6.098 11.683 6.098 12.316 5.707 12.707 L 1.707 16.707 C 1.317 17.097 0.684 17.097 0.293 16.707 C -0.097 16.316 -0.097 15.683 0.293 15.293 L 3.586 12 L 0.293 8.707 Z" />
  </svg>
);
