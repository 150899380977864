import { ChangeEventHandler, forwardRef } from 'react';
import styles from './checkbox.module.css';
import { Label } from '../../../label';
import { Icon } from '../../../symbols';

type CheckboxProps = {
  name?: string;
  id?: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ disabled, ...props }, ref) => {
    return (
      <>
        <input
          className={styles.element}
          type="checkbox"
          ref={ref}
          disabled={disabled}
          aria-disabled={disabled}
          {...props}
        />
        <div className={styles.checkbox}>
          <Icon name="checkmark" />
        </div>
      </>
    );
  },
);

type CheckboxLabelProps = CheckboxProps & {
  label: string;
};
export const CheckboxLabel = forwardRef<HTMLInputElement, CheckboxLabelProps>(
  ({ disabled, label, ...props }, ref) => {
    return (
      <Label
        htmlFor={props.id}
        aria-disabled={disabled}
        className={styles.label}
      >
        <Checkbox ref={ref} disabled={disabled} {...props} />
        {label}
      </Label>
    );
  },
);
