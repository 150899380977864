import { SvgSymbol } from '../symbol-factory';

export const Search: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 8.235 2 C 12.783 2 16.471 5.687 16.471 10.235 C 16.471 12.085 15.861 13.792 14.832 15.166 L 19.655 19.992 C 20.115 20.451 20.115 21.196 19.655 21.655 C 19.196 22.115 18.451 22.115 17.992 21.655 L 13.166 16.832 C 11.791 17.861 10.085 18.471 8.235 18.471 C 3.687 18.471 0 14.784 0 10.235 C 0 5.687 3.687 2 8.235 2 Z M 8.235 4.353 C 4.987 4.353 2.353 6.987 2.353 10.235 C 2.353 13.484 4.987 16.118 8.235 16.118 C 11.484 16.118 14.118 13.484 14.118 10.235 C 14.118 6.987 11.484 4.353 8.235 4.353 Z" />
  </svg>
);
