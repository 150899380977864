import { SymbolFactory, SymbolFactoryProps } from './symbol-factory';
import {
  depPictogramMap,
  pictogramMap,
  PictogramMap,
  type Pictograms,
} from './pictogram-map';

export { Pictograms };
const pictograms = pictogramMap as unknown as PictogramMap;
const depPictograms = depPictogramMap as unknown as PictogramMap;

export type PictogramProps = SymbolFactoryProps<Pictograms>;
export const Pictogram = SymbolFactory<Pictograms>(
  pictograms,
  depPictograms,
  100,
);
