export class ReturnUrlStore {
  private static key = 'returnUrl';

  static set(returnUrl: string) {
    return sessionStorage.setItem(
      ReturnUrlStore.key,
      encodeURIComponent(returnUrl),
    );
  }

  static get() {
    const returnUrl = sessionStorage.getItem(ReturnUrlStore.key);
    if (!returnUrl) return null;
    return decodeURIComponent(returnUrl);
  }

  static remove() {
    return sessionStorage.removeItem(ReturnUrlStore.key);
  }
}
