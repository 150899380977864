import { SvgSymbol } from '../symbol-factory';

export const Logout: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 16 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 2.5 20 L 4 20 C 4.552 20 5 19.552 5 19 C 5 18.448 4.552 18 4 18 L 2.5 18 C 2.223 18 2 17.776 2 17.5 L 2 6.5 C 2 6.224 2.223 6 2.5 6 L 4 6 C 4.552 6 5 5.552 5 5 C 5 4.448 4.552 4 4 4 L 2.5 4 C 1.119 4 0 5.119 0 6.5 L 0 17.5 C 0 18.163 0.263 18.799 0.732 19.268 C 1.201 19.736 1.836 20 2.5 20 Z" />
    <path d="M 12.707 8.293 C 12.316 7.902 11.683 7.902 11.292 8.293 C 10.901 8.684 10.901 9.317 11.292 9.708 L 12.585 11 L 5 11 C 4.447 11 4 11.448 4 12.001 C 4 12.553 4.447 13.001 5 13.001 L 12.585 13.001 L 11.292 14.293 C 10.901 14.684 10.902 15.318 11.292 15.708 C 11.683 16.099 12.316 16.099 12.707 15.708 L 15.707 12.708 C 15.895 12.52 16.001 12.266 16.001 12.001 C 16.001 11.735 15.895 11.481 15.707 11.294 L 12.707 8.293 Z" />
  </svg>
);
