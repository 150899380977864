import { EbbotLink } from '@lhb/ebbot';
import styles from '../error.module.css';
import { Button, cn, Divider } from '@lhb/ui';
import { Link } from '@remix-run/react';

type Error500Props = {
  link?: React.ElementType;
};

/**
 * @TODO: Convert to i18n compatible
 */
export function Error500({ link: AsLink = Link }: Error500Props) {
  return (
    <>
      <h1 className={cn('display', styles['heading'])}>Något gick fel</h1>
      <p className={cn('intro')}>
        Vi kan inte behandla din begäran just nu. Vi arbetar för att lösa
        problemet och tackar för ditt tålamod.
      </p>

      <div>
        <span className="text-bold">Vad du kan göra</span>
        <ul className={styles['list']}>
          <li>
            <span className="text-bold">Försök igen:</span> Vänta några minuter
            och försök igen.
          </li>
          <li>
            <span className="text-bold">Kontakta kundtjänst:</span> Om problemet
            kvarstår, kontakta vår kundtjänst för att få hjälp.
          </li>
        </ul>
      </div>
      <Divider />
      <div>
        <span className="text-bold">Kontaktuppgifter</span>
        <ul className={styles['list']}>
          <li>
            <span className="text-bold">Kundtjänst telefon:</span>{' '}
            <a href="tel:+46771440020">0771-44 00 20</a>
          </li>
          <li>
            <EbbotLink className="text-bold">Chatta med oss</EbbotLink>: Vår
            chattbot är tillgänglig dygnet runt.
          </li>
        </ul>
      </div>

      <Button asChild icon="return">
        <AsLink to="https://landshypotek.se">
          Tillbaka till landshypotek.se
        </AsLink>
      </Button>
    </>
  );
}
