import { SvgSymbol } from '../symbol-factory';

export const InfoCircle: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM13 10.2002C13 9.64795 12.5522 9.2002 12 9.2002H11C10.4478 9.2002 10 9.64795 10 10.2002C10 10.7524 10.4478 11.2002 11 11.2002V15.2002C10.4478 15.2002 10 15.6479 10 16.2002C10 16.7524 10.4478 17.2002 11 17.2002H13C13.5522 17.2002 14 16.7524 14 16.2002C14 15.6479 13.5522 15.2002 13 15.2002V10.2002ZM11.75 8.2002C12.4404 8.2002 13 7.64055 13 6.9502C13 6.25984 12.4404 5.7002 11.75 5.7002C11.0596 5.7002 10.5 6.25984 10.5 6.9502C10.5 7.64055 11.0596 8.2002 11.75 8.2002Z"
    />
  </svg>
);
