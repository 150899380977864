type ExcludeNull<T> = {
  [K in keyof T]-?: null extends T[K] ? Exclude<T[K], null> : T[K];
};

/**
 * Filters out any `null` values and makes them `undefined`.
 * It also filters out the type of the object so the TS parser understands it.
 * @param obj Object containing null types
 * @returns
 */
export function excludeNull<Data extends Record<string, unknown>>(
  obj: Data,
): ExcludeNull<Data> {
  Object.keys(obj).forEach((key) => {
    // Check if the property is null
    if (obj[key] === null) {
      // If null, delete the property
      delete obj[key];
    }
  });

  return obj as ExcludeNull<Data>;
}
