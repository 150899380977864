import { Amortization } from './pictograms/amortization';
import { ApplyLoan } from './pictograms/apply-loan';
import { ChangeTerms } from './pictograms/change-terms';
import { CloseAccount } from './pictograms/close-account';
import { Contract } from './pictograms/contract';
import { Email } from './pictograms/email';
import { Energy } from './pictograms/energy';
import { Field } from './pictograms/field';
import { Forest } from './pictograms/forest';
import { Graph } from './pictograms/graph';
import { House } from './pictograms/house';
import { Percent } from './pictograms/percent';
import { Piechart } from './pictograms/piechart';
import { Signature } from './pictograms/signature';
import { Transactions } from './pictograms/transactions';
import { SavingsAccount } from './pictograms/savings-account';
import { Sunrise } from './pictograms/sunrise';
import { Chat } from './pictograms/chat';
import { Farmer } from './pictograms/farmer';
import { SvgSymbol } from './symbol-factory';

export const pictogramMap = {
  amortization: Amortization,
  'apply-loan': ApplyLoan,
  'change-terms': ChangeTerms,
  'account-close': CloseAccount,
  'account-savings': SavingsAccount,
  contract: Contract,
  email: Email,
  energy: Energy,
  field: Field,
  forest: Forest,
  graph: Graph,
  house: House,
  percent: Percent,
  piechart: Piechart,
  signature: Signature,
  transactions: Transactions,
};

export const depPictogramMap = {
  chat: Chat,
  farmer: Farmer,
  sunrise: Sunrise,
};

export type Pictograms =
  | keyof typeof pictogramMap
  | keyof typeof depPictogramMap;
export type PictogramMap = Record<Pictograms, SvgSymbol>;
export type DeprecatedPictogram = keyof typeof depPictogramMap;
