import { cn } from '../../utils';
import { Divider } from './assets';
import { FooterDecoration } from './footer-decoration';
import styles from './site-footer.module.css';
import { PropsWithChildren } from 'react';

type FooterProps = PropsWithChildren;

export function SiteFooter({ children }: FooterProps) {
  return (
    <div className={styles['footer']}>
      <div className={cn(styles['footer-divider'])}>
        <Divider className={styles['divider-field']} />
        <FooterDecoration />
      </div>
      <footer className={cn(styles['footer-content'])}>
        <div>{children}</div>
      </footer>
    </div>
  );
}
