import '../../fonts/NHaasGroteskDSPro-55Rg.otf';
import '../../fonts/NHaasGroteskDSPro-65Md.otf';
import '../../fonts/NHaasGroteskDSStd-56It.otf';
import '../../styles/index.css';
import styles from './styles.module.css';

export type MonthNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type SeasonName = 'winter' | 'spring' | 'summer' | 'autumn';
export type Season = {
  style: string;
  name: SeasonName;
  month: MonthNumber;
};

function getInitialSeason(month: MonthNumber): Omit<Season, 'style'> {
  if (month >= 3 && month <= 4) return { month, name: 'spring' };
  if (month >= 5 && month <= 8) return { month, name: 'summer' };
  if (month >= 9 && month <= 11) return { month, name: 'autumn' };
  return { month, name: 'winter' };
}

export function getSeason(month: number): Season {
  const season = getInitialSeason(month as MonthNumber);
  return { ...season, style: styles[season.name] };
}

export function useSeason(month = new Date().getMonth() + 1): Season {
  return getSeason(month);
}
