import { SvgSymbol } from '../symbol-factory';

export const ChevronUp: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 10 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 1.707 14.707 C 1.317 15.098 0.684 15.098 0.293 14.707 C -0.098 14.317 -0.098 13.683 0.293 13.293 L 4.293 9.293 C 4.684 8.902 5.317 8.902 5.707 9.293 L 9.707 13.293 C 10.098 13.683 10.098 14.317 9.707 14.707 C 9.317 15.098 8.684 15.098 8.293 14.707 L 5 11.414 L 1.707 14.707 Z" />
  </svg>
);
