import { forwardRef } from 'react';
import * as Select from '@radix-ui/react-select';
import { cn } from '../../../../utils';
import { Icon } from '../../../symbols';
import styles from './option.module.css';

type OptionProps = Select.SelectItemProps & {
  label: React.ReactNode;
};

export const Option = forwardRef<HTMLDivElement, OptionProps>(
  ({ className, label, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={cn(styles['option'], className)}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{label}</Select.ItemText>
        <Select.ItemIndicator>
          <Icon name="checkmark" />
        </Select.ItemIndicator>
      </Select.Item>
    );
  },
);
