import { SvgSymbol } from '../symbol-factory';

export const Calendar: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 17 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 5.768 3.391 C 5.768 2.897 5.362 2.5 4.857 2.5 C 4.352 2.5 3.946 2.897 3.946 3.391 L 3.946 4.875 L 2.429 4.875 C 1.089 4.875 0 5.94 0 7.25 L 0 7.844 L 0 9.625 L 0 19.125 C 0 20.435 1.089 21.5 2.429 21.5 L 14.571 21.5 C 15.911 21.5 17 20.435 17 19.125 L 17 9.625 L 17 7.844 L 17 7.25 C 17 5.94 15.911 4.875 14.571 4.875 L 13.054 4.875 L 13.054 3.391 C 13.054 2.897 12.648 2.5 12.143 2.5 C 11.638 2.5 11.232 2.897 11.232 3.391 L 11.232 4.875 L 5.768 4.875 L 5.768 3.391 Z M 1.821 9.625 L 15.179 9.625 L 15.179 19.125 C 15.179 19.452 14.905 19.719 14.571 19.719 L 2.429 19.719 C 2.095 19.719 1.821 19.452 1.821 19.125 L 1.821 9.625 Z M 3.643 12.891 C 3.643 13.384 4.049 13.781 4.554 13.781 L 12.446 13.781 C 12.951 13.781 13.357 13.384 13.357 12.891 C 13.357 12.397 12.951 12 12.446 12 L 4.554 12 C 4.049 12 3.643 12.397 3.643 12.891 Z M 4.554 15.563 C 4.049 15.563 3.643 15.96 3.643 16.453 C 3.643 16.947 4.049 17.344 4.554 17.344 L 8.804 17.344 C 9.308 17.344 9.714 16.947 9.714 16.453 C 9.714 15.96 9.308 15.563 8.804 15.563 L 4.554 15.563 Z" />
  </svg>
);
