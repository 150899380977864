import { SvgSymbol } from '../symbol-factory';

export const Dropdown: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 10 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 1.707 14.293 C 1.316 13.902 0.683 13.902 0.293 14.293 C -0.098 14.683 -0.098 15.317 0.293 15.707 L 4.293 19.707 C 4.684 20.098 5.317 20.098 5.707 19.707 L 9.707 15.707 C 10.098 15.317 10.098 14.683 9.707 14.293 C 9.317 13.902 8.684 13.902 8.293 14.293 L 5 17.586 L 1.707 14.293 Z" />
    <path d="M 1.707 9.707 C 1.316 10.098 0.683 10.098 0.293 9.707 C -0.098 9.317 -0.098 8.683 0.293 8.293 L 4.293 4.293 C 4.683 3.902 5.316 3.902 5.707 4.293 L 9.707 8.293 C 10.098 8.683 10.098 9.317 9.707 9.707 C 9.316 10.098 8.683 10.098 8.293 9.707 L 5 6.414 L 1.707 9.707 Z" />
  </svg>
);
