import { SvgSymbol } from '../symbol-factory';

export const User: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 10 13.1 C 15.523 13.1 20 16.234 20 20.1 C 20 23.967 0 23.967 0 20.1 C 0 16.234 4.477 13.1 10 13.1 Z M 10 1 C 12.455 1 14.444 2.97 14.444 5.4 C 14.444 7.83 12.455 12 10 12 C 7.545 12 5.556 7.83 5.556 5.4 C 5.556 2.97 7.545 1 10 1 Z" />
  </svg>
);
