import { SvgSymbol } from './symbol-factory';

import { ArrowLeft } from './icons/arrow-left';
import { ArrowRight } from './icons/arrow-right';
import { Attachment } from './icons/attachment';
import { BankId } from './icons/bankid';
import { Calendar } from './icons/calendar';
import { Chat } from './icons/chat';
import { Checkmark } from './icons/checkmark';
import { CheckmarkCircle } from './icons/checkmark-circle';
import { ChevronDown } from './icons/chevron-down';
import { ChevronLeft } from './icons/chevron-left';
import { ChevronRight } from './icons/chevron-right';
import { ChevronUp } from './icons/chevron-up';
import { Cross } from './icons/cross';
import { CrossSmall } from './icons/cross-small';
import { Document } from './icons/document';
import { Download } from './icons/download';
import { Dropdown } from './icons/dropdown';
import { Edit } from './icons/edit';
import { Error } from './icons/error';
import { Euro } from './icons/euro';
import { Farm } from './icons/farm';
import { HelpCircle } from './icons/help-circle';
import { House } from './icons/house';
import { InfoCircle } from './icons/info-circle';
import { LinkExternal } from './icons/link-external';
import { Logout } from './icons/logout';
import { Mail } from './icons/mail';
import { Menu } from './icons/menu';
import { Minus } from './icons/minus';
import { MinusCircle } from './icons/minus-circle';
import { Padlock } from './icons/padlock';
import { Phone } from './icons/phone';
import { Pin } from './icons/pin';
import { Plus } from './icons/plus';
import { PlusCircle } from './icons/plus-circle';
import { Process } from './icons/process';
import { Return } from './icons/return';
import { Search } from './icons/search';
import { Settings } from './icons/settings';
import { Sme } from './icons/sme';
import { Upload } from './icons/upload';
import { User } from './icons/user';

export const iconMap = {
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'checkmark-circle': CheckmarkCircle,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'cross-small': CrossSmall,
  'help-circle': HelpCircle,
  'info-circle': InfoCircle,
  'link-external': LinkExternal,
  'minus-circle': MinusCircle,
  'plus-circle': PlusCircle,
  attachment: Attachment,
  bankid: BankId,
  calendar: Calendar,
  chat: Chat,
  checkmark: Checkmark,
  cross: Cross,
  document: Document,
  download: Download,
  dropdown: Dropdown,
  edit: Edit,
  error: Error,
  farm: Farm,
  logout: Logout,
  mail: Mail,
  padlock: Padlock,
  phone: Phone,
  process: Process,
  search: Search,
  settings: Settings,
  user: User,
  return: Return,
};

export const deprecatedIconMap = {
  euro: Euro,
  house: House,
  menu: Menu,
  minus: Minus,
  pin: Pin,
  plus: Plus,
  sme: Sme,
  upload: Upload,
};

export type Icons = keyof typeof iconMap | keyof typeof deprecatedIconMap;
export type IconMap = Record<Icons, SvgSymbol>;
export type DeprecatedIcons = keyof typeof deprecatedIconMap;
