import { SvgSymbol } from '../symbol-factory';

export const Padlock: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 14 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M 7 2 C 9.577 2 11.667 4.107 11.667 6.706 L 11.667 10.235 L 12.833 10.235 C 13.478 10.235 14 10.762 14 11.412 L 14 20.824 C 14 21.473 13.478 22 12.833 22 L 1.167 22 C 0.522 22 0 21.473 0 20.824 L 0 11.412 C 0 10.762 0.522 10.235 1.167 10.235 L 2.333 10.235 L 2.333 6.706 C 2.333 4.107 4.423 2 7 2 Z M 7 4.353 C 5.711 4.353 4.667 5.406 4.667 6.706 L 4.667 10.235 L 9.333 10.235 L 9.333 6.706 C 9.333 5.406 8.289 4.353 7 4.353 Z" />
  </svg>
);
