import { SvgSymbol } from '../symbol-factory';

export const HelpCircle: SvgSymbol = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM13.7706 11.4691C14.9595 10.6989 15.5001 10.0376 15.5001 8.73434C15.5001 4.74196 9.41144 4.18361 8.51987 8.53974C8.41794 9.03779 8.71823 9.52866 9.1906 9.63613C9.66296 9.74361 10.1285 9.42699 10.2305 8.92894C10.6858 6.70442 13.7501 6.98543 13.7501 8.73434C13.7501 9.21577 13.5744 9.43068 12.8545 9.89703L12.7357 9.97383C11.6147 10.6991 11.1251 11.2687 11.1251 12.4245V13.8084C11.1251 14.3179 11.5169 14.7309 12.0001 14.7309C12.4834 14.7309 12.8751 14.3179 12.8751 13.8084V12.4245C12.8751 12.1174 13.0086 11.9621 13.651 11.5464L13.7706 11.4691ZM12.05 18.2002C12.7404 18.2002 13.3 17.6406 13.3 16.9502C13.3 16.2598 12.7404 15.7002 12.05 15.7002C11.3597 15.7002 10.8 16.2598 10.8 16.9502C10.8 17.6406 11.3597 18.2002 12.05 18.2002Z"
    />
  </svg>
);
