import { isMobile } from '@lhb/utils';

export function autoStart(autoStartToken: string, userAgent: string) {
  if (!autoStartToken) throw new Error('Missing autoStartToken');
  const check = isMobile(userAgent);

  return check.android.device && !check.other.androidNonStandard
    ? `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`
    : `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
}
